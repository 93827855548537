import React, { useState } from "react";
import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import CustomDatatable from "src/components/organisms/datatable/elements";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import {
  useGetAllQuotationsByUserMutation,
  useGetQuotationsByIdMutation,
} from "src/services/api-service/user";
import { useSearchParams } from "react-router-dom";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { useDeleteQuotationMutation } from "src/services/api-service/quotations";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import exportFromJSON from "export-from-json";
import { formatDataForCustomerQuotationExport } from "src/helpers";

const Datatable = () => {
  const [getListMutation] = useGetQuotationsByIdMutation<any>();
  const [getAllQuotationsByUser] = useGetAllQuotationsByUserMutation<any>();
  const [updateId, setUpdateId] = useState("");
  const [deleteItem] = useDeleteQuotationMutation<any>();
  const [url] = useSearchParams();
  const customerId: string = url?.get("id") ?? "";
  const customerName = url?.get("name") ?? "";
  const [filtersData] = useState({
    id: customerId,
  });

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "users-datatable-quotations",
    mutation: getListMutation,
    filtersData,
  });

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const [columnDefs] = useState<any[]>(columns(handleOpenDeleteModal));

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };

    updateId &&
      deleteItem(body)
        .unwrap()
        .then((data: any) => {
          showToast(
            TOASTR_TYPES.SUCCESS,
            data?.message || data?.data?.message || data?.original?.message,
          );
          fetchData({ currentPage: pagination?.currentPage, query: "" });
        })
        .catch((err) => {
          console.log(err);
        });

    setDeleteModalOpen(false);
  };

  const exportData = () => {
    getAllQuotationsByUser({ id: customerId })
      .unwrap()
      .then((res: any) => {
        const fileName = `${customerName.toLowerCase()}_quotations`;
        const exportType = exportFromJSON.types.xls;

        exportFromJSON({
          data: formatDataForCustomerQuotationExport(res?.data),
          fileName,
          exportType,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  return (
    <>
      <CustomDatatable
        title={customerName || ""}
        description={`${pagination.total} Total Quotation${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={(e: any) => {
          setUpdateId(e?.data?.id);
        }}
        button={
          <CustomButton
            disabled={!data?.length}
            variant="secondary-icon-btn"
            onClick={exportData}
          >
            <div className="flex items-center gap-2">
              <i className="shrink-0">
                <Icon.McsIcExportPdf />
              </i>
              <span>Export</span>
            </div>
          </CustomButton>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this Group?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </>
  );
};

export default Datatable;
