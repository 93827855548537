import React, { useEffect, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import SettingsTabs from "./elements/settings-tabs";
import { IBreadcrumbItem, TITLES } from "src/types";

const Settings = () => {
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Settings",
      isDisabled: true,
    },
    {
      title: "General Settings",
    },
  ];

  const [data, setData] = useState(breadcrumbsData);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.SETTINGS;

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, [data]);

  const tabChanged = (title: any) => {
    setData([
      {
        title: "Settings",
        isDisabled: true,
      },
      {
        title: title || "General Settings",
        hidden: false,
      },
    ]);
  };

  return (
    <PanelLayout breadcrumbsData={data}>
      <SettingsTabs tabChanged={tabChanged} />
    </PanelLayout>
  );
};

export default Settings;
