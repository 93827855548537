import React from "react";
import { Icon } from "src/components/atoms/icons";
import useFormattedDate from "src/hooks/useFormattedDate";

export const columns = (
  { handleOpenDeleteModal }: any,
  { handleOpenEditModal }: any,
) => {
  return [
    {
      headerName: "Client Name",
      field: "user_name",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      cellRenderer: ({ value }: any) => {
        function toTitleCase(str: string) {
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
        }
        return <span>{toTitleCase(value)}</span>;
      },
    },
    {
      headerName: "Latest Activity",
      field: "updated_at",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: false,
      cellRenderer: ({ value }: any) => <div>{useFormattedDate(value)}</div>,
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      resizable: false,
      width: 100,
      cellRenderer: () => (
        <div className="flex h-full items-center justify-end">
          <button className="cursor-pointer p-8" onClick={handleOpenEditModal}>
            <Icon.McsIcEdit />
          </button>

          <button
            className="cursor-pointer p-8"
            onClick={handleOpenDeleteModal}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
