import React from "react";
import { Link } from "react-router-dom";

const CargoShipperComponent = () => {
  return (
    <div className="center mt-42 flex flex-col items-center justify-center ">
      <img src={"/assets/images/head-success-img.jpg"} alt="sucess"></img>
      <h1 className="mb-34 flex items-center text-20 font-medium">
        <img
          className=" mr-8 animate-[spin_2s_linear_infinite]"
          src={"/assets/images/loading-success.svg"}
          alt="tick"
        />
        We are now gathering all options for you
      </h1>
      <div className="flex flex-col justify-center bg-primary-50 px-60  text-center text-primary-400">
        <h1 className=" my-36 text-24 font-semibold">
          Get the best deals in no time.
        </h1>
        <div className=" flex flex-col items-center  justify-center ">
          <div className="mb-26 flex items-center ">
            <img
              className="mr-8"
              src={"/assets/images/sucess-tick.png"}
              alt="tick"
            />
            <h1 className="text-20 font-normal">Get multiple quotations</h1>
          </div>
          <div className="mb-26 flex  items-center">
            <img
              className="mr-8"
              src={"/assets/images/sucess-tick.png"}
              alt="tick"
            />
            <h1 className="text-20 font-normal">Simple and fast booking</h1>
          </div>
          <div className="mb-26 flex  items-center">
            <img
              className="mr-8"
              src={"/assets/images/sucess-tick.png"}
              alt="tick"
            />
            <h1 className="text-20 font-normal">24/7 Customer support</h1>
          </div>
        </div>
        <div className="mb-24 flex items-center gap-6 border-t-2 border-gray-300 ">
          <img
            className=""
            src={"/assets/images/maersk-success-img.png"}
            alt="maersk"
          ></img>
          <img
            className=" h-11"
            src={"/assets/images/oocl-success-img.png"}
            alt="occl-img"
          ></img>
          <img
            className="h-[70px]"
            src={"/assets/images/cosco-success-img.png"}
            alt="maersk"
          ></img>
        </div>
        <h1 className="mb-13 text-black">
          In Partnership with{" "}
          <Link
            className="text-primary-400 underline decoration-1 underline-offset-4"
            to={""}
          >
            10+ Cargo Shippers
          </Link>
        </h1>
      </div>
    </div>
  );
};

export default CargoShipperComponent;
