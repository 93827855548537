import React, { useState, useEffect } from "react";
import SelectInput from "src/components/atoms/select-input";
import QuotationCard from "../quotation-card";
import { IGenerateQuotation, quotationForOptions } from "./types";
import { CARD_TYPES } from "../quotation-card/data";
import TextInput from "src/components/atoms/text-input";
import CustomDatePicker from "src/components/atoms/date-picker/custom-date-picker.component";
import CargoSpecs from "../cargo-specs";
import LocationPicker from "src/components/atoms/location-picker";
import { useQuotationsContext } from "src/context/quotations-context";
import CountryPicker from "src/components/atoms/country-picker";
import PortPicker from "src/components/atoms/port-picker";
import PricingDetails from "../pricing-details";
import AddCustomerContainer from "src/pages/user-management/elements/customers/datatable/add-customer/add-customer.container";
import { useGetCountriesListMutation } from "src/services/api-service/countries";
import { useGetPostcodesListMutation } from "src/services/api-service/postcode";
import { useGetUsersListMutation } from "src/services/api-service/user";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { Icon } from "src/components/atoms/icons";
import { checkBooleanOptions, formatDateResult } from "src/utils";
import usePickupDestination from "src/hooks/usePickupDestination";
import { getCurrentDate, isCustomQuotation } from "src/helpers";
import { CONSTANTS } from "src/constants";

const GenerateQuotationComponent = ({
  quotationTypes,
  handleCardClick,
  serviceTypes,
}: IGenerateQuotation) => {
  const [getListMutation] = useGetUsersListMutation<any>();
  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const { formikQuotation } = useQuotationsContext();
  const [quotationFor, setQuotationFor] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id") ?? "";
  const tab = queryParams.get("tab") ?? "";

  const {
    originCountries,
    destinationCountries,
    originPorts,
    destinationPorts,
    resetLocationsData,
  } = usePickupDestination(formikQuotation?.values);

  const handleCloseAddCustomerModal = (data: any) => {
    setAddModalOpen(false);
    if (data) {
      const option = {
        customerid: data?.id,
        customername: data?.name,
      };
      setOptions([option].concat(options));
      formikQuotation?.setFieldValue("customer_id", data?.id);
    }
  };

  useEffect(() => {
    formikQuotation?.setValues({
      ...formikQuotation.values,
      date: getCurrentDate(),
      cargo_item_specs: [
        {
          cm_kg: 1,
          colli_type: "Pallet",
          quantity: 0,
          length: 0,
          width: 0,
          height: 0,
          volume: 0,
          weight: 0,
          stackable: 1,
        },
      ],
    });
    console.log(formikQuotation);
  }, [tab]);

  useEffect(() => {
    console.log(id);
    getData();
    id && getDetails();
  }, [id]);

  const getData = async (params?: any) => {
    const data: any = await getListMutation({
      perPage: params?.perPage || 100,
      search: params?.search || "",
    })
      .unwrap()
      .catch((err: any) => {
        // console.log(err)
      });
    const finalData: any = data?.data?.data;
    if (finalData && finalData.length > 0) {
      setOptions([
        ...finalData.map((d: any) => {
          return {
            ...d,
            customerid: d?.customerid.toString(),
          };
        }),
      ]);
    }
  };

  const getDetails = () => {
    const body = {
      id: id,
    };
    try {
      id &&
        getDetailsMutation(body)
          .unwrap()
          .then((response: any) => {
            const syncData: any = response?.data;
            let is_cm = false;

            if (syncData?.cargo_item_specs?.length > 0) {
              is_cm = Boolean(+syncData?.cargo_item_specs[0]?.cm_kg);
            }

            formikQuotation.setValues((values: any) => ({
              ...values,
              // quotation_type: "internal",
              quotation_type: syncData?.quotations[0]?.quotation_type,
              customer_id: JSON.stringify(syncData?.customer_id),
              reference_no:
                syncData?.reference_no || syncData?.quotations[0]?.reference_no,
              date: syncData?.date || "",
              source_country: syncData?.source_country,
              source_location: syncData?.source_location,
              source_port: syncData?.source_port,
              destination_country: syncData?.destination_country,
              destination_location: syncData?.destination_location,
              destination_port: syncData?.destination_port,
              service_type: syncData?.service_type,
              cargo_item_specs: syncData?.cargo_total_dimensions?.length
                ? syncData?.cargo_total_dimensions
                : syncData?.cargo_item_specs,
              total_cargo_dimensions:
                !!syncData?.cargo_total_dimensions?.length,
              is_cm,
              charges: syncData?.quotations[0]?.priceBreakUp,
            }));

            if (syncData?.cargo_item_specs?.length > 0) {
              is_cm = syncData?.cargo_item_specs[0]?.cm_kg;
              const items: any[] = syncData?.cargo_item_specs?.map(
                (item: any) => {
                  return {
                    ...item,
                    stackable: checkBooleanOptions(item.stackable),
                  };
                },
              );
              formikQuotation?.setFieldValue("cargo_item_specs", items);
            }

            if (
              !syncData?.customer ||
              syncData?.customer?.email == CONSTANTS.DEFAULT_CUSTOMER_EMAIL
            ) {
              setQuotationFor("2");
            } else {
              setQuotationFor("1");
            }

            console.log("datsetting...", formikQuotation.values);
          });
    } catch (err) {
      console.log(err);
    }
  };

  const [getCountriesList] = useGetCountriesListMutation();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        await getCountriesList({});
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [getCountriesList]);

  // Fetch postcodes for source location
  const [getPostcodesList, { data: PostcodesData }] =
    useGetPostcodesListMutation();
  useEffect(() => {
    const fetchPostcodes = async () => {
      try {
        await getPostcodesList({});
      } catch (error) {
        console.error("Error fetching source postcodes:", error);
      }
    };
    fetchPostcodes();
  }, [getPostcodesList]);

  const handleChange = (e: any) => {
    console.log(e);
    const delayDebounceFn = setTimeout(() => {
      getData({ perPage: 100, search: "" });
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  };

  useEffect(() => {
    !id &&
      quotationFor == "2" &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });

    if (quotationFor == "2") {
      formikQuotation.setFieldValue("customer_id", "");
    }
  }, [quotationFor]);

  useEffect(() => {
    !id &&
      formikQuotation.values.service_type &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
  }, [formikQuotation.values.service_type]);

  useEffect(() => {
    !id &&
      formikQuotation?.values?.customer_id &&
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
  }, [formikQuotation?.values?.customer_id]);

  return (
    <div className="p-24">
      <div className="flex flex-col gap-1">
        <h1 className="text-24 font-bold leading-32 text-gray-800">
          Create your Quote
        </h1>
        <div className="text-14 font-normal leading-20 text-gray-500-base">
          Complete the required fields and get your personalized quote
        </div>
      </div>
      <div className="mb-[176px] mt-10 flex flex-col rounded-[10px] border border-border">
        {/* Quotation Type */}
        <div className="mb-12 px-24 pt-16">
          <h2 className="text-18 font-semibold leading-28 text-primary-800">
            Quotation Type
          </h2>
        </div>
        <div className="mb-24 grid grid-cols-1 gap-x-2.5 gap-y-10 px-24 py-16 sm:grid-cols-2 md:grid-cols-1 md:gap-x-20 lg:grid-cols-3 xl:grid-cols-4">
          {quotationTypes.map((card, idx) => (
            <QuotationCard
              key={card?.id}
              {...card}
              handleCardClick={() =>
                handleCardClick(card?.value, CARD_TYPES.QUOTATION_TYPE)
              }
              cardType={CARD_TYPES.QUOTATION_TYPE}
              isActive={formikQuotation?.values.quotation_type === card?.value}
            />
          ))}
        </div>

        {formikQuotation?.values?.quotation_type && (
          <>
            <span className="hidden"> Who is this quotation for </span>
            <div className="mb-24 px-24 pb-16">
              <h4 className="mb-10 text-18 font-semibold leading-28 text-primary-800">
                Who is this Quotation for?
              </h4>
              <div className="grid grid-cols-12 gap-3">
                <SelectInput
                  name="quotationFor"
                  options={quotationForOptions}
                  value={quotationFor}
                  onSelect={(e: string) => {
                    setQuotationFor(e);
                  }}
                  placeholder="Select here..."
                  containerClassName="col-span-12 sm:col-span-4"
                />
                {quotationFor && quotationFor === "1" && (
                  <SelectInput
                    name="customer"
                    searchable={true}
                    options={[
                      {
                        label: (
                          <span className="text-14 font-medium leading-20 !text-blue-1">
                            + Add Customer
                          </span>
                        ),
                        customername: "Add Customer",
                        value: "Add Customer",
                      },
                    ].concat(options)}
                    value={formikQuotation.values.customer_id}
                    onChange={handleChange}
                    onSelect={(e: any) => {
                      if (e == "Add Customer") {
                        setAddModalOpen(true);
                      } else {
                        formikQuotation.setFieldValue("customer_id", e);
                      }
                    }}
                    placeholder="Select"
                    containerClassName="col-span-12 sm:col-span-5"
                  />
                )}
              </div>
            </div>
            {quotationFor &&
              (quotationFor === "2" ||
                (quotationFor === "1" &&
                  formikQuotation?.values?.customer_id)) && (
                <>
                  <span className="hidden"> Service Type </span>
                  <div className="mb-24 px-24">
                    <h3 className="mb-12 text-18 font-semibold leading-28 text-primary-800">
                      Service Type
                    </h3>
                    <div className="grid grid-cols-1 gap-x-2.5 gap-y-[40px] py-16  sm:grid-cols-2 md:grid-cols-1 md:gap-x-[80px] lg:grid-cols-3 xl:grid-cols-4">
                      {serviceTypes.map((card, idx) => (
                        <QuotationCard
                          key={card?.id}
                          {...card}
                          handleCardClick={() =>
                            handleCardClick(
                              card?.value,
                              CARD_TYPES.SERVICE_TYPE,
                            )
                          }
                          cardType={CARD_TYPES.SERVICE_TYPE}
                          isActive={
                            formikQuotation?.values.service_type == card?.value
                          }
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            {quotationFor &&
              (quotationFor === "2" ||
                (quotationFor === "1" &&
                  formikQuotation?.values?.customer_id)) &&
              formikQuotation?.values?.service_type && (
                <>
                  <span className="hidden"> Routing Details </span>
                  <div className="mb-24 px-24 pb-16">
                    <h3 className="pb-12 text-18 font-semibold leading-28 text-primary-800">
                      Routing Details
                    </h3>
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 xl:col-span-5">
                        <TextInput
                          name="reference_no"
                          type="text"
                          label="Reference No."
                          placeholder="Type here.."
                          value={formikQuotation?.values?.reference_no}
                          onChange={formikQuotation?.handleChange}
                          // required={true}
                        />
                      </div>
                      <div className="col-span-12 xl:col-span-5">
                        <CustomDatePicker
                          name="date"
                          label="Cargo Ready date"
                          containerClassName="cargo-ready-date-picker"
                          value={formikQuotation?.values?.date}
                          onChange={(e: any) => {
                            formikQuotation?.setFieldValue(
                              "date",
                              formatDateResult(new Date(e)),
                            );
                            console.log(new Date(e));
                          }}
                          errorMessage={formikQuotation?.errors?.date}
                          showError={formikQuotation?.touched?.date}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
                    {/* <h4 className="pb-12 text-16 font-semibold leading-28 text-primary-800">
                    Pickup Location
                  </h4> */}
                    <div className="col-span-12 xl:col-span-4">
                      <CountryPicker
                        placeholder="Enter a Country"
                        name="source_country"
                        label="Pickup Country"
                        value={formikQuotation?.values?.source_country}
                        onChange={(value: string) => {
                          isCustomQuotation(formikQuotation) &&
                            formikQuotation?.setFieldValue(
                              "source_country",
                              value,
                            );
                        }}
                        onSelect={(value: string) => {
                          resetLocationsData("originPorts");
                          resetLocationsData("destinationCountries");
                          resetLocationsData("destinationPorts");

                          formikQuotation?.setValues({
                            ...formikQuotation?.values,
                            source_port: "",
                            destination_country: "",
                            destination_port: "",
                          });
                          formikQuotation?.setFieldValue(
                            "source_country",
                            value,
                          );
                        }}
                        countries={
                          isCustomQuotation(formikQuotation)
                            ? []
                            : originCountries
                        } // Pass the fetched countries data here
                        required={true}
                        allowCustomValue={true}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-4">
                      <PortPicker
                        placeholder="Select a Port"
                        name="source_port"
                        label="Origin Port"
                        showSearch={true}
                        value={formikQuotation?.values?.source_port}
                        onKeyDown={(e: any) => {
                          console.log(e);
                        }}
                        onChange={(value: string) => {
                          isCustomQuotation(formikQuotation) &&
                            formikQuotation?.setFieldValue(
                              "source_port",
                              value,
                            );
                        }}
                        onSelect={(value: string) => {
                          resetLocationsData("destinationCountries");
                          resetLocationsData("destinationPorts");
                          formikQuotation?.setValues({
                            ...formikQuotation?.values,
                            destination_country: "",
                            destination_port: "",
                          });
                          formikQuotation?.setFieldValue("source_port", value);
                        }}
                        ports={
                          isCustomQuotation(formikQuotation) ? [] : originPorts
                        } // Pass the fetched ports data here
                        required={true}
                        suffixIcon={
                          formikQuotation?.values?.quotation_type ===
                          "internal" ? (
                            <span className="z-10">
                              <Icon.McsIcDownArrow />
                            </span>
                          ) : (
                            ""
                          )
                        }
                        allowCustomValue={true}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-4">
                      <LocationPicker
                        placeholder="Enter Pickup Location"
                        name="source_location"
                        label="Pickup Location"
                        value={formikQuotation?.values?.source_location}
                        onChange={(value: string) => {
                          formikQuotation.setFieldValue(
                            "source_location",
                            value,
                          );
                        }}
                        onSelect={(value: string) => {
                          formikQuotation.setFieldValue(
                            "source_location",
                            value,
                          );
                        }}
                        postcodes={PostcodesData || []} // Pass postcodes data here
                        countryCode={formikQuotation?.values?.source_port?.substring(
                          0,
                          2,
                        )}
                        disabled={!Boolean(formikQuotation.values.source_port)}
                      />
                    </div>
                  </div>
                  <div className="mb-24 grid grid-cols-12 gap-5 px-24 pb-16">
                    {/* <h4 className="pb-12 text-16 font-semibold leading-28 text-primary-800">
                    Destination Location
                  </h4> */}
                    <div className="col-span-12 xl:col-span-4">
                      <CountryPicker
                        placeholder="Enter a Country"
                        name="destination_country"
                        label="Destination Country"
                        value={formikQuotation?.values?.destination_country}
                        onChange={(value: string) => {
                          isCustomQuotation(formikQuotation) &&
                            formikQuotation?.setFieldValue(
                              "destination_country",
                              value,
                            );
                        }}
                        onSelect={(value: string) => {
                          resetLocationsData("destinationPorts");
                          formikQuotation?.setValues({
                            ...formikQuotation?.values,
                            destination_port: "",
                          });
                          formikQuotation?.setFieldValue(
                            "destination_country",
                            value,
                          );
                        }}
                        countries={
                          isCustomQuotation(formikQuotation)
                            ? []
                            : destinationCountries
                        } // Pass the fetched countries data here
                        required={true}
                        allowCustomValue={true}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-4">
                      <PortPicker
                        placeholder="Select a Port"
                        name="destination_port"
                        label="Destination Port"
                        showSearch={true}
                        value={formikQuotation?.values?.destination_port}
                        onChange={(value: string) => {
                          isCustomQuotation(formikQuotation) &&
                            formikQuotation?.setFieldValue(
                              "destination_port",
                              value,
                            );
                        }}
                        onSelect={(value: string) => {
                          formikQuotation?.setFieldValue(
                            "destination_port",
                            value,
                          );
                        }}
                        ports={
                          isCustomQuotation(formikQuotation)
                            ? []
                            : destinationPorts
                        }
                        suffixIcon={
                          formikQuotation?.values?.quotation_type ===
                          "internal" ? (
                            <span className="z-10">
                              <Icon.McsIcDownArrow />
                            </span>
                          ) : (
                            ""
                          )
                        }
                        required={true}
                        allowCustomValue={true}
                      />
                    </div>
                    <div className="col-span-12 xl:col-span-4">
                      <LocationPicker
                        placeholder="Enter Destination Location"
                        name="destination_location"
                        label="Destination Location"
                        value={formikQuotation?.values?.destination_location}
                        onChange={(value: string) => {
                          formikQuotation.setFieldValue(
                            "destination_location",
                            value,
                          );
                        }}
                        onSelect={(value: string) => {
                          formikQuotation.setFieldValue(
                            "destination_location",
                            value,
                          );
                        }}
                        postcodes={PostcodesData || []} // Pass postcodes data here
                        countryCode={formikQuotation?.values?.destination_port?.substring(
                          0,
                          2,
                        )}
                        disabled={
                          !Boolean(formikQuotation.values.destination_port)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            {quotationFor &&
              (quotationFor === "2" ||
                (quotationFor === "1" &&
                  formikQuotation?.values?.customer_id)) &&
              formikQuotation?.values?.source_country &&
              formikQuotation?.values?.source_port &&
              formikQuotation?.values?.destination_country &&
              formikQuotation?.values?.destination_port &&
              formikQuotation?.values?.date && (
                <>
                  <span className="hidden"> Cargo Specs </span>
                  <div className="mb-24 px-24 pb-16">
                    <CargoSpecs />
                  </div>
                  {formikQuotation?.values?.quotation_type ===
                    quotationTypes[1].value && <PricingDetails />}
                </>
              )}
            {isAddModalOpen && (
              <AddCustomerContainer
                handleCloseAddModal={handleCloseAddCustomerModal}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateQuotationComponent;
