import React from "react";
import { Icon } from "src/components/atoms/icons";

export const columns = (
  { handleOpenDeleteModal }: any,
  { handleDisabledEditModal }: any,
  { handleOpenEditModal }: any,
) => {
  return [
    {
      headerName: "Name",
      field: "name",
      width: 100,
      headerClass: "", // Add this line
      flex: 1,
      // rowDrag: true,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: true,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      suppressMovable: true,
      editable: false,
      suppressNavigable: true,
      minWidth: 200,
      resizable: false,
      cellRenderer: ({ value }: any) => {
        const convertToTitleCase = (inputString: string) => {
          return inputString
            ?.toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        };
        return <span>{convertToTitleCase(value)}</span>;
      },
    },
    {
      headerName: "Actions",
      field: "",
      lockPosition: "right",
      suppressNavigable: true,
      width: 100,
      resizable: false,
      cellRenderer: (data?: any) => (
        <div className="flex h-full items-center justify-end">
          <button
            className="cursor-pointer p-8"
            disabled={handleDisabledEditModal(data)}
            onClick={handleOpenEditModal}
          >
            <Icon.McsIcEdit />
          </button>
          <button
            className="cursor-pointer p-8"
            disabled={handleDisabledEditModal(data)}
            onClick={handleOpenDeleteModal}
          >
            <Icon.McsIcDelete />
          </button>
        </div>
      ),
      cellStyle: { paddingLeft: 12, paddingRight: 12 },
      sortable: false,
    },
  ];
};
