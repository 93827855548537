import React, { useEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomDrawer from "src/components/molecules/custom-drawer";
import { useQuotationsContext } from "src/context/quotations-context";
import useCustomDrawer from "src/hooks/useCustomDrawer";
import { TABS } from "src/pages/quotations/elements/quotation-tabs/types";
import "./style.css";
import { useAppSelector } from "src/store/hook";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { generateErrorMessage } from "src/helpers";
interface Props {
  onSuccess?: (val: boolean) => void;
}

const SRPreviewComponent = ({
  onSuccess = (val) => {
    console.log(val);
    /* empty fun */
  },
}: Props) => {
  const { isButtonDisabled } = useAppSelector(quotationsSelector);
  const { isOpen, toggle } = useCustomDrawer();
  const { formikQuotation } = useQuotationsContext();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get("tab") ?? "";
  const [isDisabled, setDisabled] = useState(false);
  const [dimensions, setDimensions] = useState({
    volume: 0,
    weight: 0,
    quantity: 0,
  });

  const WeightLimitation: any = formikQuotation?.values?.is_cm
    ? 20000
    : 44092.452;
  const VolumeLimitation: any = formikQuotation?.values?.is_cm ? 40 : 1412.59;

  const calculateTotalDimensions = () => {
    let totalVolume = 0;
    let totalWeight = 0;
    let totalQuantity = 0;
    formikQuotation?.values?.cargo_item_specs?.forEach((item: any) => {
      if (formikQuotation?.values?.total_cargo_dimensions) {
        totalVolume += item.volume;
        totalWeight += parseFloat(item.weight) || 0;
      } else {
        totalWeight += parseFloat(item.weight) * item.quantity || 0;
        totalVolume += item.height * item.width * item.length * item.quantity;
      }
      totalQuantity += parseInt(item.quantity);
    });
    // Assuming `setDimensions` is a state updater function
    setDimensions(() => ({
      volume: totalVolume,
      weight: totalWeight,
      quantity: totalQuantity,
    }));
  };

  const totalWeight = (dimensions?.weight / 1).toFixed(2);
  const totalVolume = formikQuotation?.values?.total_cargo_dimensions
    ? ((dimensions?.volume || 0) / 1)?.toFixed(3)
    : (
        (dimensions?.volume || 0) /
        (formikQuotation?.values?.is_cm ? 1000000 : 1728)
      ).toFixed(3);

  useEffect(() => {
    if (totalWeight > WeightLimitation || totalVolume > VolumeLimitation) {
      setDisabled(true);
    }
  }, [dimensions]);

  const data = [
    {
      title: "Service Type",
      value: formikQuotation?.values?.service_type?.toUpperCase() || "-",
    },
    {
      title: "Origin",
      value: formikQuotation?.values?.source_country || "-",
    },
    {
      title: "Destination",
      value: formikQuotation?.values?.destination_country || "-",
    },
    {
      title: "Total Quantity",
      value: dimensions?.quantity || "-",
    },
    {
      title: "Weight",
      value:
        (totalWeight || "-") + (formikQuotation?.values?.is_cm ? " Kg" : " lb"),
      error: (
        <span className="text-14 font-medium leading-20 text-helper-text ">
          {totalWeight > WeightLimitation &&
            generateErrorMessage(
              "weight",
              totalWeight > WeightLimitation,
              `${WeightLimitation} ${formikQuotation.values.is_cm ? "kg" : "Pounds"}`,
            )}
        </span>
      ),
    },
    {
      title: "Volume",
      value:
        (totalVolume || "-") +
        // ((dimensions?.volume).toFixed(3) || "-") +
        (formikQuotation?.values?.is_cm ? " M³" : " ft³"),
      error: (
        <span className="text-14 font-medium leading-20 text-helper-text ">
          {totalVolume > VolumeLimitation &&
            generateErrorMessage(
              "volume",
              totalVolume > VolumeLimitation,
              `${VolumeLimitation} ${formikQuotation.values.is_cm ? "CBM" : "CBF"}`,
            )}
        </span>
      ),
      // value:
      //   (formikQuotation?.values?.total_cargo_dimensions
      //     ? ((dimensions?.volume || 0) / 1)?.toFixed(3)
      //     : (
      //         (dimensions?.volume || 0) /
      //         (formikQuotation?.values?.is_cm ? 1000000 : 1728)
      //       ).toFixed(3) || "-") +
      //   // ((dimensions?.volume).toFixed(3) || "-") +
      //   (formikQuotation?.values?.is_cm ? " M³" : " ft³"),
    },
  ];

  useEffect(() => {
    calculateTotalDimensions();
  }, [formikQuotation]);

  useEffect(() => {
    console.log(formikQuotation?.values);
    if (formikQuotation?.values?.total_cargo_dimensions) {
      let totalVolume = 0;
      formikQuotation?.values?.cargo_item_specs.forEach((item: any) => {
        if (item.height * item.width * item.length) {
          totalVolume +=
            (item.height || 0) *
            (item.width || 0) *
            (item.length || 0) *
            (item.quantity || 0);
        } else {
          totalVolume += item.volume;
        }
      });
      const specs: any[] = [
        {
          cm_kg: formikQuotation?.values?.cargo_item_specs[0]?.is_cm,
          colli_type: formikQuotation?.values?.cargo_item_specs[0]?.colli_type,
          quantity: formikQuotation?.values?.cargo_item_specs[0]?.quantity,
          length: formikQuotation?.values?.cargo_item_specs[0]?.length,
          width: formikQuotation?.values?.cargo_item_specs[0]?.width,
          height: formikQuotation?.values?.cargo_item_specs[0]?.height,
          volume:
            totalVolume /
            (formikQuotation?.values?.cargo_item_specs[0]?.height
              ? 1000000
              : 1),
          weight: formikQuotation?.values?.cargo_item_specs[0]?.weight,
          stackable: formikQuotation?.values?.cargo_item_specs[0]?.stackable,
        },
      ];
      formikQuotation?.setValues({
        ...formikQuotation.values,
        cargo_item_specs: [...specs],
      });
    }
  }, [formikQuotation?.values?.total_cargo_dimensions]);

  const useCheckDisabled = () => {
    if (
      !formikQuotation?.values?.source_country ||
      !formikQuotation?.values?.source_port ||
      !formikQuotation?.values?.destination_country ||
      !formikQuotation?.values?.destination_port ||
      !formikQuotation?.values?.date
    ) {
      setDisabled(true);
    } else {
      if (
        !formikQuotation?.values?.cargo_item_specs?.length ||
        (formikQuotation?.values?.quotation_type === "custom" &&
          (!formikQuotation?.values?.charges["preCarriage"]?.length ||
            !formikQuotation?.values?.charges["origin"]?.length ||
            !formikQuotation?.values?.charges["destination"]?.length ||
            !formikQuotation?.values?.charges["oceanFreight"]?.length))
      ) {
        setDisabled(true);
      } else {
        const items: any[] = formikQuotation?.values?.cargo_item_specs;
        const result = checkForEmptyValue(items);
        const isPreCarriage = checkForEmptyValue(
          formikQuotation?.values?.charges["preCarriage"],
          true,
        );
        const isFOBCarriage = checkForEmptyValue(
          formikQuotation?.values?.charges["fobCharges"],
          true,
        );
        const isOceanFreight = checkForEmptyValue(
          formikQuotation?.values?.charges["oceanFreight"],
          true,
        );
        if (!result.success) {
          console.log(
            `Empty value found at index ${result.index}, key '${result.key}'`,
          );

          if (formikQuotation?.values?.quotation_type === "internal") {
            setDisabled(true);
          } else {
            if (
              !isPreCarriage.success ||
              !isFOBCarriage.success ||
              !isOceanFreight.success
            ) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          }
        } else {
          setDisabled(false);
        }
      }
    }
  };

  function checkForEmptyValue(array: any[], other?: boolean) {
    for (let i = 0; i < array?.length; i++) {
      const obj = array[i];
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (other) {
            if (!key || !obj[key])
              return { success: false, key: key, index: i };
          } else {
            if (!formikQuotation?.values?.total_cargo_dimensions) {
              if (
                (key === "colli_type" ||
                  key === "quantity" ||
                  key === "length" ||
                  key === "width" ||
                  key === "weight" ||
                  key === "height") &&
                !obj[key]
              ) {
                return { success: false, key: key, index: i };
              }
            } else {
              if ((key === "volume" || key === "weight") && !obj[key]) {
                return { success: false, key: key, index: i };
              }
            }
          }
        }
      }
    }
    return { success: true };
  }

  useEffect(() => {
    useCheckDisabled();
  }, [formikQuotation]);

  return (
    <div
      className={
        tabValue === TABS.HISTORY.value
          ? "hidden"
          : "fixed bottom-0 left-0 right-0 z-10 md:left-[250px]"
      }
      id="sr-preview"
    >
      <CustomDrawer
        isOpen={isOpen}
        toggle={toggle}
        header={
          <div
            className="inline-flex rounded-tl-[5px] rounded-tr-[5px] bg-primary-100 text-16 font-medium leading-24"
            onClick={toggle}
          >
            <span className="px-10 py-5"> Shipment Request Preview</span>
            <button className="flex w-[85px] items-center justify-center px-10">
              <i className={`${isOpen ? "" : "rotate-[180deg]"} `}>
                <Icon.MscIcChevronDown />
              </i>
              <div className="text-primary-400">{isOpen ? "Hide" : "Show"}</div>
            </button>
          </div>
        }
      >
        <div className="flex flex-wrap items-center justify-between gap-3 bg-primary-100 px-36 py-12">
          <div className="flex flex-wrap gap-4 rounded-[10px] bg-primary-50 px-24 py-12">
            {data?.map((d, i) => {
              return (
                <div
                  className={
                    !(
                      formikQuotation?.values?.total_cargo_dimensions && i === 3
                    )
                      ? "flex min-w-[106px] flex-col gap-1"
                      : "hidden"
                  }
                  key={i}
                >
                  <p className="text-14 font-medium leading-20 text-gray-400">
                    {d.title}
                  </p>
                  <p>{d.value}</p>
                  {d?.error}
                </div>
              );
            })}
          </div>
          <div className="">
            <CustomButton
              onClick={() => {
                formikQuotation.submitForm();
                formikQuotation?.values?.quotation_type === "internal" &&
                  onSuccess(true);
              }}
              disabled={isDisabled || isButtonDisabled}
              variant="primary"
            >
              {"Generate Quotation"}
            </CustomButton>
          </div>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default SRPreviewComponent;
