import React from "react";
import { Icon } from "src/components/atoms/icons";
import "./style.css";
import CustomButton from "src/components/atoms/button";
import { useSearchParams } from "react-router-dom";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { setLoading } from "src/store/features/alerts";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { useSendQuotationEmailByIdsMutation } from "src/services/api-service/quotations";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { CONSTANTS } from "src/constants";
import { setSelectedQuotations } from "src/store/features/quotations";
import useUser from "src/hooks/useUser";

const QuotationSelectedComponent = () => {
  const [sendEmail] = useSendQuotationEmailByIdsMutation<any>();
  const [url] = useSearchParams();
  const id: string = url?.get("id") ?? "";
  const dispatch = useAppDispatch();
  const user = useUser();
  const { data, filteredQuotations, selectedQuotations } =
    useAppSelector(quotationsSelector);

  const baseUrl = process.env.REACT_APP_API_URL;

  const sendViaEmail = () => {
    const body = {
      id: id,
      quotationIds: selectedQuotations,
    };

    id &&
      sendEmail(body)
        .unwrap()
        .then((data: any) => {
          console.log(data);
          showToast(
            TOASTR_TYPES.SUCCESS,
            data?.message || data?.data?.message || data?.original?.message,
          );
        });
  };

  const downloadSinglePdf = async (id: string | number) => {
    try {
      try {
        const token = user?.access_token;
        const downloadUrl =
          baseUrl + `/api/quotations-pdf-ids/${JSON.stringify([id])}`;
        const response = await fetch(downloadUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const contentType = response.headers.get("content-type") ?? "";
          const contentDisposition =
            response.headers.get("content-disposition") || "";
          const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDisposition,
          );
          const filename =
            filenameMatch && filenameMatch[1]
              ? filenameMatch[1].replace(/['"]/g, "")
              : "quotation.pdf";

          const blob = new Blob([await response.blob()], {
            type: contentType,
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error: any) {
        console.log(error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAllPdfs = async () => {
    try {
      dispatch(setLoading(true));
      for (const element of selectedQuotations) {
        await downloadSinglePdf(element);
      }
      showToast(
        TOASTR_TYPES.SUCCESS,
        `The pdf${selectedQuotations?.length > 1 ? "'s" : ""} has been downloaded successfully.`,
      );
    } catch (err) {
      showToast(TOASTR_TYPES.ERROR, "Something went wrong.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="relative">
      <div
        className={`border-primary-600-25 fixed bottom-0 left-0 right-0 z-[99] flex ${selectedQuotations?.length == 0 ? "h-0 overflow-hidden" : "h-[90px] px-48 py-24"} justify-between gap-24 border bg-primary-25  transition-all md:ml-[250px]`}
      >
        <p className="flex gap-1 py-10 font-medium">
          <button
            className="flex cursor-pointer"
            onClick={() => dispatch(setSelectedQuotations([]))}
          >
            <Icon.McsIcModalClose fill="red" />
          </button>
          <p>
            {selectedQuotations?.length == filteredQuotations.length
              ? "All"
              : selectedQuotations?.length}{" "}
            {selectedQuotations?.length > 1 ? "Quotations" : "Quotation"}{" "}
            selected
          </p>
        </p>
        <div className="w-1/3 gap-4 xl:flex">
          <div className={data?.customer ? "flex-1" : "hidden"}>
            {data?.customer?.email !== CONSTANTS.DEFAULT_CUSTOMER_EMAIL && (
              <CustomButton
                onClick={() => sendViaEmail()}
                variant="secondary"
                className="w-full"
                disabled={!data?.customer}
              >
                Send via email
              </CustomButton>
            )}
          </div>
          <div className="mt-10 w-[212px] flex-1 xl:mt-0">
            <CustomButton
              onClick={() => downloadAllPdfs()}
              variant="primary"
              className="w-full"
            >
              Export PDF
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationSelectedComponent;
