export interface IBreadcrumbItem {
  title: string;
  hidden?: boolean;
  href?: string;
  isDisabled?: boolean;
}

export enum VARIANTS {
  "PRIMARY" = "primary",
  "SECONDARY" = "secondary",
}

export const TITLES = {
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  FORGOT_PASSWORD: "Forgot Password",
  RESET_PASSWORD: "Reset Password",
  VERIFY_OTP: "Verify OTP",
  QUOTATIONS: "Quotations",
  QUOTATION_HISTORY: "Quotation History",
  MARGIN_GROUPS: "Margin Groups",
  PRICES: "Prices & Rates",
  ADMIN_USER_MANAGEMENT: "Admin User Management",
  USER_MANAGEMENT: "Customer Management",
  SETTINGS: "Settings",
  BOOKINGS: "Bookings",
  BOOKING_DETAILS: "Booking Details",
  HELP_SUPPORT: "Help & Support",
  FAQ: "Frequently Asked Questions",
  CONTACT: "Still need help? Contact us directly!",
};

export const MAILING_ADDRESS = process.env.MAILING_ADDRESS ?? "lcl@marinair.gr";

export type TODO = any;

export interface ICountryPortPicker {
  id?: string;
  name: string;
  label?: string;
  size?: "medium" | "large" | "small";
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  value?: string;
  required?: boolean;
  errorMessage?: string | null;
  showError?: boolean;
  onClick?: React.MouseEventHandler;
  onChange?: any;
  onSelect?: any;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  onKeyDown?: any;
  classes?: {
    inputClassName?: string;
    containerClassName?: string;
    errorLabelClass?: string;
  };
  autoFocus?: boolean;
  allowNumbersOnly?: boolean;
  suffix?: any;
  prefix?: any;
  ports?: ICountryPort[]; // Add the ports property
  countries?: ICountryPort[]; // Add the countries property
  suffixIcon?: any;
  showSearch?: boolean;
  allowCustomValue?: boolean;
}

export interface ICountryPort {
  locode?: string;
  aliases?: string; // If aliases are optional
  name?: string;
  code: string;
}
