import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import { useGetUserByIdMutation } from "src/services/api-service/user";
import CustomSelect from "src/components/atoms/custom-select";
import { COUNTRIES } from "src/store/common/countries";

const AddCustomerComponent = ({
  formik,
  resetError,
  handleCloseAddModal,
  updateId,
  closeModal,
}: any) => {
  const [getItemMutation] = useGetUserByIdMutation<any>();

  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const requiredKeys = [
    "name",
    "email",
    "city",
    "country",
    "contactPersonEmail",
  ];
  const formValues = formik.values;
  const [storedData, setStoredData] = useState<any>({
    name: "",
    email: "",
    registrationNumber: "",
    paymentTerms: "",
    vatNumber: "",
    street: "",
    buildingNo: "",
    postalCode: "",
    city: "",
    country: "",
    contactPersonName: "",
    contactPersonPhoneNumber: "",
    contactPersonEmail: "",
  });

  useLayoutEffect(() => {
    const allKeys = Object.keys(formValues);
    const requiredValuesPresent = requiredKeys.every((key) => {
      const value = formValues[key];
      return (
        value !== undefined &&
        value !== null &&
        !(typeof value === "object" && Object.keys(value).length === 0) &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "string" && value.trim() === "")
      );
    });
    if (requiredValuesPresent) {
      if (updateId && storedData) {
        const allFieldsSame = allKeys.every(
          (key) =>
            formValues[key] === storedData[key] ||
            (formValues[key] === "" && storedData[key] === null),
        );
        setIsDisabled(allFieldsSame);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [formValues, requiredKeys, storedData, updateId]);

  useEffect(() => {
    getDataById();
  }, [updateId]);

  useEffect(() => {
    !updateId && setIsModalOpen(true);
  }, []);

  const getDataById = async () => {
    if (updateId) {
      const response: any = await getItemMutation({ id: updateId })
        .unwrap()
        .catch((err: any) => {
          // Handle error if needed
        });
      const data = response?.data || null;
      data && setStoredData(data);
      data &&
        formik.setValues({
          name: data?.name || "",
          email: data?.email || "",
          registrationNumber: data?.registrationNumber || "",
          paymentTerms: data?.paymentTerms || "",
          vatNumber: data?.vatNumber || "",
          street: data?.street || "",
          buildingNo: data?.buildingNo || "",
          postalCode: data?.postalCode || "",
          city: data?.city || "",
          country: data?.country || "",
          contactPersonName: data?.contactPersonName || "",
          contactPersonPhoneNumber: data?.contactPersonPhoneNumber || "",
          contactPersonEmail: data?.contactPersonEmail || "",
        });
      setIsModalOpen(true);
    }
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    document.body.style.overflow = "auto";
    handleCloseAddModal();
  };

  useEffect(() => {
    closeModal && toggle();
  }, [closeModal]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomModal
        title={(updateId ? "Edit" : "Add") + " Customer"}
        isOpen={isModalOpen}
        toggle={toggle}
        maskClosable={false}
        icon={<Icon.McsIcChevronBack />}
        width={876}
        innerClass="custom-common-modal"
        footer={
          <div className="w-full justify-end gap-4 lg:flex">
            <div className="order-1 lg:order-2">
              <CustomButton
                variant="primary"
                htmlType="submit"
                className="w-full"
                disabled={isDisabled}
              >
                Save
              </CustomButton>
            </div>
            <div className="order-2 mt-10 lg:order-1 lg:mt-0">
              <CustomButton
                variant="secondary"
                onClick={toggle}
                className="w-full"
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
      >
        <div className="grid">
          <h3 className="py-22 pt-10 text-24 font-medium leading-32 text-gray-800">
            Customer Info
          </h3>
          <div className="grid gap-x-4 lg:grid-cols-3">
            <div>
              <TextInput
                name="name"
                type="text"
                label="Customer Name"
                placeholder="ACI Logistics DWC LLC"
                classes={{
                  containerClassName: "mb-24 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.name}
                showError={Boolean(formik.touched.name)}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors.name}
                required={true}
              />
            </div>
            <div>
              <TextInput
                name="email"
                type="email"
                label="Customer Email"
                placeholder="aleem.shaikh@aci.ae"
                classes={{
                  containerClassName: "mb-24 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.email}
                showError={Boolean(formik.touched.email)}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors.email}
                required={true}
              />
            </div>
          </div>
          <h3 className="py-22 text-24 font-medium leading-32 text-gray-800">
            Customer Address
          </h3>
          <div className="grid gap-x-[16px] lg:grid-cols-3">
            <div>
              <TextInput
                name="street"
                type="text"
                label="Street"
                placeholder="ex. 4140 Parker road"
                classes={{
                  containerClassName: "mb-24 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.street}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.street)}
                errorMessage={formik.errors.street}
              />
            </div>

            <div>
              <TextInput
                name="postalCode"
                type="text"
                label="Postal Code"
                placeholder="ex. 22112"
                classes={{
                  containerClassName: "mb-24 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.postalCode}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.postalCode)}
                errorMessage={formik.errors.postalCode}
              />
            </div>
            <div>
              <TextInput
                name="city"
                type="text"
                label="City"
                placeholder="ex. Monaco"
                classes={{
                  containerClassName: "mb-24 lg:mb-8 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.city}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.city)}
                errorMessage={formik.errors.city}
                required={true}
              />
            </div>
            <div>
              <CustomSelect
                label="Country"
                name="country"
                placeholder="Select an option"
                showSearch={true}
                value={formik.values.country}
                showError={Boolean(formik.touched.country)}
                onChange={(e: any) => {
                  resetError();
                  formik.setFieldValue("country", e);
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors.country}
                options={COUNTRIES}
                allowClear
                required={true}
              />
            </div>
          </div>
          <h3 className="py-24 text-24 font-medium leading-32 text-gray-800">
            Contact Person Info
          </h3>
          <div className="grid gap-x-[16px] lg:grid-cols-3">
            <div>
              <TextInput
                name="contactPersonName"
                type="text"
                label="Contact Person Name"
                placeholder="Aleem Shaikh"
                classes={{
                  containerClassName: "mb-24 lg:mb-0 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.contactPersonName}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.contactPersonName)}
                errorMessage={formik.errors.contactPersonName}
              />
            </div>
            <div>
              <TextInput
                name="contactPersonPhoneNumber"
                type="text"
                label="Contact Person Phone Number"
                placeholder="ex. +31 123-4567-89"
                classes={{
                  containerClassName: "mb-24 lg:mb-0 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.contactPersonPhoneNumber}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.contactPersonPhoneNumber)}
                errorMessage={formik.errors.contactPersonPhoneNumber}
              />
            </div>
            <div>
              <TextInput
                name="contactPersonEmail"
                type="email"
                label="Contact Person Email"
                placeholder="aleem.shaikh@aci.ae"
                classes={{
                  containerClassName: "mb-24 lg:mb-0 custom-input",
                  inputClassName: "",
                }}
                value={formik.values.contactPersonEmail}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                showError={Boolean(formik.touched.contactPersonEmail)}
                errorMessage={formik.errors.contactPersonEmail}
                required={true}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </form>
  );
};

export default AddCustomerComponent;
