import React, { useEffect, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import ContentWrapper from "src/components/layouts/content-wrapper";
import CustomLink from "src/components/atoms/custom-link";
import QuotationsResultFilters from "./elements/quotation-results-filters";
import QuotationResultsCard from "./elements/quotation-results-card";
import { IBreadcrumbItem } from "src/types";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useNavigate, useSearchParams } from "react-router-dom";
import CargoShipperSuccess from "../quotations/elements/cargo-shipper-success";
import { useQuotationsContext } from "src/context/quotations-context";
import { useAppDispatch } from "src/store/hook";
import { setSelectedQuotations } from "src/store/features/quotations";

const QuotationsResultComponent = () => {
  const [url] = useSearchParams();
  const id: string = url?.get("id") ?? "";

  const dispatch = useAppDispatch();

  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const { formikQuotation } = useQuotationsContext();
  const [reload] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    console.log("in");
    id && getDetailsMutation({ id });
  };

  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Generate Quotations",
      href: "/admin/quotations?tab=Generate%20Quotations",
    },
    {
      title: "Results",
    },
  ];

  useEffect(() => {
    if (reload) getDetails();
  }, [reload]);

  useEffect(() => {
    console.log(formikQuotation?.values?.quotation_type);
    if (formikQuotation?.values?.quotation_type === "custom") {
      formikQuotation.setFieldValue("quotation_type", "internal");
      navigate("/admin/quotations?tab=Generate%20Quotations&id=" + id);
    } else {
      getDetails();
    }
  }, [success]);
  useEffect(() => {
    dispatch(setSelectedQuotations([]));
  }, []);

  const href = `/admin/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <PanelLayout isResultsPage={true} breadcrumbsData={breadcrumbsData}>
      <>
        <div className={!success ? "hidden" : ""}>
          <CargoShipperSuccess toggle={setSuccess} />
        </div>
        <ContentWrapper>
          <CustomLink href={href}>Back to edit search</CustomLink>
          <QuotationsResultFilters getDetails={getDetails} isCustomer={false} />
          <QuotationResultsCard />
        </ContentWrapper>
      </>
    </PanelLayout>
  );
};

export default QuotationsResultComponent;
