import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import DatatableComponent from "./datatable.component";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { IResponse } from "src/services/types";
import useLoadingError from "src/hooks/useLoadingError";
import {
  useAddMarginGroupMutation,
  useUpdateMarginGroupMutation,
} from "src/services/api-service/margin-groups";
import { marginGroupSchema } from "src/validations/margin-group-schema";
import useTitleCase from "src/hooks/useTitleCase";

const DatatableContainer = () => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [addItem] = useAddMarginGroupMutation<any>();
  const [updateItem] = useUpdateMarginGroupMutation<any>();
  const [id, setId] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const { titleCasedString, handleInputChange } = useTitleCase();

  const formik = useFormik({
    initialValues: {
      name: "",
      customerType: "",
      margin_percentage: "",
      description: "",
      customerId: [],
    },
    validationSchema: marginGroupSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      setReFetchData(false);
      setCloseModal(false);
      startLoading();
      let APIHIT;
      !id &&
        (APIHIT = addItem({
          ...values,
          name: titleCasedString?.name || formik?.values?.name,
          margin_percentage: parseFloat(formik?.values?.margin_percentage),
          customerId: JSON.stringify(formik?.values?.customerId),
        }));
      id &&
        (APIHIT = updateItem({
          ...values,
          name: titleCasedString?.name || formik?.values?.name,
          id,
          margin_percentage: parseFloat(formik?.values?.margin_percentage),
          customerId: JSON.stringify(formik?.values?.customerId),
        }));
      setId("");
      APIHIT &&
        APIHIT.unwrap()
          .then((data) => {
            showToast(TOASTR_TYPES.SUCCESS, data?.message);
            setReFetchData(true);
            formik.resetForm();
            setCloseModal(true);
          })
          .catch((err: IResponse) => {
            console.log(err);
          })
          .finally(() => {
            stopLoading();
          });
    },
  });

  useEffect(() => {
    handleInputChange(formik.values.name, "name");
  }, [formik.values.name]);

  const getCurrentId = (id: string) => {
    setCloseModal(false);
    setId(id);
  };

  const setCloseModalState = (value: boolean) => {
    setCloseModal(value);
  };

  return (
    <DatatableComponent
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      getId={getCurrentId}
      closeModal={closeModal}
      setCloseModal={setCloseModalState}
      reFetchData={reFetchData}
    />
  );
};

export default DatatableContainer;
