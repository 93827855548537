import React, { useEffect, useState } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import PricesTabs from "./elements/prices-tabs";
import { IBreadcrumbItem, TITLES } from "src/types";
import { useSearchParams } from "react-router-dom";

const Prices = () => {
  const [url] = useSearchParams();
  const type = url?.get("token") ?? "";
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Prices & Rates",
      isDisabled: true,
    },
    {
      title: "Pricings",
      hidden: type ? true : false,
    },
  ];
  const [data, setData] = useState(breadcrumbsData);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.PRICES;

  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
    tabChanged(null);
  }, []);

  const tabChanged = (title: any) => {
    setData([
      {
        title: "Prices & Rates",
        isDisabled: true,
      },
      {
        title: title || "Pricings",
        hidden: false,
      },
    ]);
  };

  return (
    <PanelLayout breadcrumbsData={data}>
      <PricesTabs tabChanged={tabChanged} />
    </PanelLayout>
  );
};

export default Prices;
