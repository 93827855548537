import React, { useState } from "react";
import CustomSwitch from "src/components/atoms/custom-switch";
import { TODO, VARIANTS } from "src/types";
import CargoItem from "../cargo-item";
import { CONSTANTS } from "src/constants";
import { useQuotationsContext } from "src/context/quotations-context";
import { convertKgCM, convertPoundKg, convertVolumeUnit } from "src/helpers";

const CargoSpecsComponent = () => {
  const [totalDimensions, setTotalDimensions] = useState(false);
  const { formikQuotation, addCargoItem, changeUnit } = useQuotationsContext();
  const [unit, setUnit] = useState(
    formikQuotation?.values?.is_cm
      ? CONSTANTS.MEASUREMENT_UNITS.METRIC
      : CONSTANTS.MEASUREMENT_UNITS.IMPERIAL,
  );
  const [cargoItems, setCargoItems] = useState(
    formikQuotation?.values?.cargo_item_specs || [],
  );

  const createNewCargoItem = () => {
    addCargoItem();

    setTimeout(() => {
      window.scrollTo({
        top: window.scrollY + 150,
        behavior: "smooth",
      });
    }, 200);
  };

  const dimensionsChanged = (isPrev: boolean) => {
    let totalVolume = 0;
    let totalWeight = 0;
    if (isPrev) {
      if (!formikQuotation?.values?.total_cargo_dimensions) {
        setCargoItems(
          JSON.parse(JSON.stringify(formikQuotation?.values?.cargo_item_specs)),
        );
        formikQuotation?.values?.cargo_item_specs.forEach(
          (item: any, index: number) => {
            totalVolume +=
              ((item.height || 0) *
                (item.width || 0) *
                (item.length || 0) *
                (item.quantity || 0)) /
              (!item.cm_kg || !formikQuotation?.values?.is_cm
                ? 1 / 0.000578704
                : 1000000);
            totalWeight += (item.weight || 0) * (item.quantity || 0);
          },
        );
        const item = {
          volume: (totalVolume / 1).toFixed(3),
          weight: (totalWeight / 1).toFixed(2),
        };
        formikQuotation?.setFieldValue("cargo_item_specs", [item]);
      } else {
        formikQuotation?.setFieldValue("cargo_item_specs", cargoItems);
      }
    } else {
      if (!formikQuotation?.values?.total_cargo_dimensions) {
        setCargoItems(
          JSON.parse(JSON.stringify(formikQuotation?.values?.cargo_item_specs)),
        );
        formikQuotation?.values?.cargo_item_specs.forEach(
          (item: any, index: number) => {
            totalVolume +=
              ((item.height || 0) *
                (item.width || 0) *
                (item.length || 0) *
                (item.quantity || 0)) /
              (!item.cm_kg || !formikQuotation?.values?.is_cm
                ? 1 / 0.000578704
                : 1000000);
            totalWeight += (item.weight || 0) * (item.quantity || 0);
          },
        );
        const item = {
          volume: (totalVolume / 1).toFixed(3),
          weight: (totalWeight / 1).toFixed(2),
        };
        formikQuotation?.setFieldValue("cargo_item_specs", [item]);
      } else {
        formikQuotation?.setFieldValue("cargo_item_specs", cargoItems);
      }
    }
  };

  const changeLocalUnit = (unit: boolean) => {
    const updatedItems = cargoItems.map((item: any) => {
      const height = convertKgCM(item.height, unit);
      const width = convertKgCM(item.width, unit);
      const length = convertKgCM(item.length, unit);
      const weight = convertPoundKg(item.weight, unit);
      const volume = convertVolumeUnit(item.volume, unit);

      return {
        ...item,
        cm_kg: unit ? 1 : 0,
        length: length,
        width: width,
        height: height,
        weight: weight,
        volume: volume,
      };
    });

    setCargoItems(updatedItems);
  };

  return (
    <>
      <div className="flex flex-col justify-between overflow-hidden pb-12 md:flex-row md:items-center">
        <h3 className="mb-4 text-18 font-semibold leading-28 text-primary-800">
          Your Cargo Specs
        </h3>
        <div className="flex flex-col justify-between gap-0 md:flex-row md:gap-[24px]">
          <div className="flex items-center">
            <CustomSwitch
              label="Enter Cargo Total Dimensions"
              variant={VARIANTS.SECONDARY}
              size="small"
              value={formikQuotation?.values?.total_cargo_dimensions}
              defaultChecked={formikQuotation?.values?.total_cargo_dimensions}
              onChange={(val: any) =>
                setTotalDimensions((prev) => {
                  formikQuotation.setFieldValue(
                    "total_cargo_dimensions",
                    !formikQuotation?.values?.total_cargo_dimensions,
                  );
                  dimensionsChanged(!!prev);
                  return !prev;
                })
              }
            />
          </div>
          <div className="flex items-center text-left">
            <CustomSwitch
              label={unit.LABEL}
              defaultChecked
              variant={VARIANTS.SECONDARY}
              value={formikQuotation?.values?.is_cm}
              onChange={(e: any) => {
                changeUnit(!!e);
                changeLocalUnit(!!e);
                formikQuotation.setFieldValue(
                  "is_cm",
                  !formikQuotation?.values?.is_cm,
                );
                setUnit(
                  e
                    ? CONSTANTS.MEASUREMENT_UNITS.METRIC
                    : CONSTANTS.MEASUREMENT_UNITS.IMPERIAL,
                );
              }}
              // checked={unit.NAME == CONSTANTS.MEASUREMENT_UNITS.METRIC.NAME}
              checked={formikQuotation?.values?.is_cm}
              labelClassName="w-[45px]"
              size="small"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        {formikQuotation?.values?.cargo_item_specs?.length &&
          formikQuotation?.values?.cargo_item_specs?.map(
            (cargoItem: TODO, idx: number) => (
              <CargoItem
                id={idx}
                key={idx}
                unit={unit}
                item={cargoItem}
                totalDimensions={totalDimensions}
              />
            ),
          )}
        <div>
          <button
            onClick={createNewCargoItem}
            className={
              formikQuotation?.values?.total_cargo_dimensions ? "hidden" : ""
            }
          >
            <span className="text-24 font-semibold leading-32 text-blue-300">
              +
            </span>
            <span className={"text-18 font-semibold leading-28 text-blue-300"}>
              Add another cargo item
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CargoSpecsComponent;
