import { Select } from "antd";
import React from "react";
import { Icon } from "src/components/atoms/icons";
const { Option } = Select;

interface Props {
  columnOption: string[];
  sendFilteredColumns: (data: any) => void;
  values: any;
  setFiltersData: any;
  isShow: boolean;
}
export default function Filters({
  columnOption,
  sendFilteredColumns,

  isShow,
}: Readonly<Props>) {
  return (
    <div
      className={
        isShow
          ? "mt-5 flex w-full items-start justify-end space-x-2 pb-5"
          : "hidden"
      }
    >
      <div className="">
        <div className="w-auto md:w-full ">
          <Select
            mode="multiple"
            className="multiSelect select-status rounded-md border-2 bg-white py-1"
            placeholder="Show"
            defaultValue={columnOption}
            style={{ width: "100%", minWidth: "135px" }}
            maxTagCount="responsive"
            onChange={sendFilteredColumns}
            suffixIcon={<Icon.McsIcEye />}
          >
            {columnOption.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
