import { useState } from "react";

interface IRecaptchaState {
  recaptcha: string;
  setRecaptcha: (arg0: string) => void;
  isVerified: boolean;
}

const useRecaptcha = (): IRecaptchaState => {
  const [recaptcha, setRecaptcha] = useState("");

  return {
    recaptcha,
    setRecaptcha,
    isVerified: true,
  };
};

export default useRecaptcha;
