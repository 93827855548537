import React, { useEffect } from "react";
import { useFormik } from "formik";
import PersonalInformationComponent from "./personal-information.component";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { IResponse } from "src/services/types";
import useLoadingError from "src/hooks/useLoadingError";
import { useUpdateCurrentUserMutation } from "src/services/api-service/settings";
import { currentUserSchema } from "src/validations/current-user-schema";
import { useAppDispatch } from "src/store/hook";
import { setUser } from "src/store/features/auth";
import useUser from "src/hooks/useUser";

const PersonalInformationContainer = ({ toggle, getToggle }: any) => {
  const { loading, error, reset, startLoading, stopLoading } =
    useLoadingError();
  const [updateCurrentUser] = useUpdateCurrentUserMutation<any>();
  const convertToTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const user = useUser();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      // phone: "",
      // gender: "",
      display_name: "",
      role: "",
    },
    validationSchema: currentUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (user) {
      const name: string = user?.name;
      const firstName: string = name?.split(" ")?.[0] || "";
      const lastName: string = name.replace(firstName + " ", "");
      formik.setValues({
        ...formik.values,
        role: convertToTitleCase(user?.role) || "",
        first_name: firstName || "",
        last_name: lastName || "",
        email: user?.email || "",
        // phone: user?.phone || "",
        // gender: user?.gender || "",
        display_name: user?.display_name || "",
      });
    }
  }, [user, toggle]);

  function handleSubmit(values?: any) {
    formik.setTouched({
      first_name: true,
      last_name: true,
      email: true,
      // phone: true,
      // gender: true,
      display_name: true,
      role: true,
    });

    if (formik.isValid) {
      startLoading();
      const { first_name, last_name, display_name, email } = formik.values;
      const name = `${first_name} ${last_name}`;

      const body = {
        ...(name && { name }),
        ...(display_name && { display_name }),
        ...(email && { email }),
        // ...(gender && { gender }),
        // ...(phone && { phone }),
      };

      updateCurrentUser(body)
        .unwrap()
        .then((data: any) => {
          const updatedUser = {
            ...user,
            name,
            display_name,
            email,
            // gender,
            // phone,
          };
          dispatch(setUser(updatedUser));
          getToggle(!toggle);
          showToast(
            TOASTR_TYPES.SUCCESS,
            data?.message || "Your personal information updated successfully",
          );
        })
        .catch((err: IResponse) => {
          console.log(err);
        })
        .finally(() => {
          stopLoading();
        });
    }
  }

  return (
    <PersonalInformationComponent
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
      handleSubmit={handleSubmit}
      toggle={toggle}
    />
  );
};

export default PersonalInformationContainer;
