import React from "react";
import { Switch } from "antd";
import "./style.css";
import { ICustomSwitch } from "./types";
import { VARIANTS } from "src/types";
import { Icon } from "../icons";

const CustomSwitch = ({
  label,
  variant = VARIANTS.PRIMARY,
  onChange,
  defaultChecked,
  checked,
  labelClassName,
  size = "default",
}: ICustomSwitch) => {
  return (
    <>
      {label && (
        <label
          className={`mr-5 text-16 font-normal leading-24 text-gray-1 ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <Switch
        className={variant + "-switch"}
        defaultChecked={defaultChecked}
        onChange={onChange}
        checked={checked}
        checkedChildren={
          variant === VARIANTS.SECONDARY ? <Icon.McsIcCheckoutline /> : null
        }
        size={size}
      />
    </>
  );
};

export default CustomSwitch;
