import React from "react";
import Datatable from "./datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";

const Pricing = () => {
  return (
    <ContentWrapper>
      <Datatable />
    </ContentWrapper>
  );
};

export default Pricing;
