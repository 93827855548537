import React from "react";
import { Icon } from "src/components/atoms/icons";
import "./breadcrumbs.css";
import { Breadcrumb } from "antd";
import { IBreadcrumbs } from "./types";

const BreadCrumbsComponent = ({ breadcrumbsData }: IBreadcrumbs) => {
  const convertToTitleCase = (inputString: string) => {
    return inputString
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const filtered: any = breadcrumbsData?.filter((item: any) => !item?.hidden);
  const filteredTitleCase = filtered.map((item: any) => ({
    ...item,
    title: convertToTitleCase(item.title),
  }));

  return (
    <div className="order-2 flex w-full gap-4 md:order-1 md:w-auto">
      <div>
        <div className="flex flex-wrap items-center gap-[2px]">
          <div className="p-4">
            <Icon.McsIcHome />
          </div>
          <span>
            <Icon.McsIcChevronRight />
          </span>
          <Breadcrumb
            separator={<Icon.McsIcChevronRight />}
            className="*:items-center *:gap-x-2"
          >
            {filteredTitleCase.map((item: any, index: number) => (
              <Breadcrumb.Item
                key={index}
                className={`${index == filteredTitleCase?.length - 1 || item?.isDisabled ? "pointer-events-none" : ""}`}
              >
                <a href={item.href ?? "#"}>{item.title}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbsComponent;
