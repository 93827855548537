import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "src/components/atoms/button";

import useCheckDisabled from "src/hooks/useCheckDisabled";
import { Button } from "antd";
import { Icon } from "src/components/atoms/icons";
import UploadModal, {
  formatFileSize,
} from "src/pages/customer/confirm-quotation/components/upload-modal";

const ConnectDatabaseComponent = ({
  formik,
  storedData,
  fileList,
  setFileList,
}: any) => {
  const navigate = useNavigate();
  const [url] = useSearchParams();
  const currentId = url?.get("id");

  const [data] = useState<{ title: string; url: string }>({
    title: "",
    url: "",
  });

  const { isDisabled, handleInputChange } = useCheckDisabled();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    navigate("/admin/prices");
  };

  useLayoutEffect(() => {
    const requiredKeys: any[] = [];
    const delayDebounceFn = setTimeout(() => {
      handleInputChange(formik.values, requiredKeys, storedData, currentId);
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [isDisabled, data, currentId]);

  const removeFile = (id: any) => {
    setFileList(fileList.filter((item: any) => item.uid != id));
  };

  return (
    <div className="grid pt-24 xl:grid-cols-12">
      <div className="col-span-12 xl:col-span-6">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <h2 className="text-24 font-bold leading-32 text-gray-800 ">
            {currentId ? "Edit" : "Connect"} Datasource{" "}
          </h2>
          <div className="flex flex-col gap-3">
            <h3 className="text-18 font-semibold leading-28 text-gray-900">
              Import Pricing
            </h3>
            <div className="my-15 flex flex-wrap items-center gap-3">
              <Button
                className="flex items-center hover:!text-slate-500"
                icon={<Icon.McsIcPlusBlack />}
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  setIsModalOpen(true);
                }}
              >
                Upload file here
              </Button>

              {fileList?.map((file: any) => (
                <>
                  {parseInt(formatFileSize(file.size ?? 0)) != 0 && (
                    <div
                      className="flex justify-between gap-3 rounded-lg border border-gray-300 px-12 py-5 text-16"
                      key={file.uid}
                    >
                      <span className="text-gray-500-base">{file.name}</span>
                      <button onClick={() => removeFile(file.uid)}>
                        <Icon.McsIcCross fill="red" width="24" height="24" />
                      </button>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="flex gap-2.5">
            <CustomButton
              htmlType="submit"
              variant="primary"
              block={false}
              disabled={fileList.length == 0}
            >
              Save
            </CustomButton>
            <CustomButton
              variant="secondary"
              block={false}
              className=""
              onClick={handleCancel}
            >
              Cancel
            </CustomButton>
          </div>
          <UploadModal
            allowMultipleFiles={false}
            open={isModalOpen}
            acceptedFileTypes=".xls,.xlsx,.csv"
            onOk={() => {
              document.body.style.overflow = "unset";
              setIsModalOpen(false);
            }}
            onCancel={() => {
              setFileList([]);
              document.body.style.overflow = "unset";
              setIsModalOpen(false);
            }}
            fileList={fileList}
            setFileList={setFileList}
          />
        </form>
      </div>
    </div>
  );
};

export default ConnectDatabaseComponent;
