import React, { useEffect } from "react";
import AuthLayout from "src/components/layouts/auth-layout";

import SignInForm from "../elements/sign-in-form";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";
import { useNavigate } from "react-router-dom";
import { TITLES } from "src/types";
import { userCusSelector } from "src/store/features/auth/customer/selectors";
import { logout } from "src/store/features/auth/actions";
import { clearToken, showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { setAuthCusToken } from "src/store/features/auth/customer";

const Login = () => {
  const user = useAppSelector(userSelector);
  const cusUser = useAppSelector(userCusSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cusUser) {
      //Logout
      dispatch(logout());
      dispatch(setAuthCusToken(""));
      clearToken();
      showToast(TOASTR_TYPES.SUCCESS, "You are logged out as customer");
    }

    if (user) {
      navigate("/admin/quotations?tab=Generate%20Quotations");
    }
  }, [user, cusUser]);

  const APP_TITLE = process.env.REACT_APP_TITLE;
  const TAB_TITLE = TITLES.LOGIN;
  useEffect(() => {
    document.title = TAB_TITLE + " | " + APP_TITLE;
  }, []);

  return (
    <AuthLayout>
      <div className="">
        <SignInForm />
      </div>
    </AuthLayout>
  );
};

export default Login;
