import React, { useEffect, useState } from "react";
import CustomDatatable from "src/components/organisms/datatable/elements";
import usePagination from "src/hooks/usePagination";
import {
  useDeleteQuotationMutation,
  useGetCustomerQuotationsMutation,
} from "src/services/api-service/quotations";
import { getColumns } from "./columns";
import Filters from "./filters";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import { showToast } from "src/utils";

export default function QuotationHistoryDatatable() {
  const [getQuotations] = useGetCustomerQuotationsMutation();

  const [filtersData, setFiltersData] = useState({
    mot: null,
    type: null,
    status: null,
    service_type: [],
  });

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "quotations-datatable",
    mutation: getQuotations,
    filtersData,
  });

  /* ========== Delete History ========== */
  const [updateId, setUpdateId] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItem] = useDeleteQuotationMutation();

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      updateId &&
        deleteItem(body)
          .unwrap()
          .then((data: any) => {
            console.log(data);
            showToast(
              "SUCCESS",
              data?.message || data?.data?.message || data?.original?.message,
            );
            fetchData({ currentPage: pagination?.currentPage, query: "" });
          });
    } catch (err) {
      console.log(err);
    }
    setDeleteModalOpen(false);
  };

  /* ========== Column & Filtering ========== */
  const columns = getColumns(handleOpenDeleteModal);

  const [columnDefs] = useState(columns);

  const [columnFilter, setColumnFilter] = useState<string[]>([]);

  const [columnDefsFilter] = useState([
    ...columns.map((column) => column.headerName),
  ]);

  useEffect(() => {
    setColumnFilter([...columnDefsFilter]);
  }, []);

  return (
    <>
      <CustomDatatable
        title={"Quotation History"}
        description={`${pagination.total} Total quotation${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs.filter((column) =>
          columnFilter.includes(column.headerName),
        )}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onRowClicked={(e: any) => {
          setUpdateId(e?.data?.id);
        }}
        clearSearch={clearSearch}
        filters={
          <Filters
            columnOption={columnDefsFilter}
            sendFilteredColumns={setColumnFilter}
            values={filtersData}
            setFiltersData={setFiltersData}
            isShow={false}
          />
        }
        button={
          <Filters
            columnOption={columnDefsFilter}
            sendFilteredColumns={setColumnFilter}
            values={filtersData}
            setFiltersData={setFiltersData}
            isShow={true}
          />
        }
      />

      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this history?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
        onCancel={() => setDeleteModalOpen(false)}
        className="custom-delete-modal"
      />
    </>
  );
}
