import React, { useState } from "react";
import GenerateQuotationComponent from "./generate-quotation.component";
import { IQuotationCard } from "../quotation-card/types";
import { CARD_TYPES } from "../quotation-card/data";
import { useQuotationsContext } from "src/context/quotations-context";

const GenerateQuotationContainer = ({ clicked }: any) => {
  const { formikQuotation } = useQuotationsContext();

  const [quotationTypes, setQuotationTypes] = useState<IQuotationCard[]>([
    {
      id: 1,
      title: "Internal Quotation",
      value: "internal",
      description: "Make a query and adjust results",
      isActive: false,
    },
    {
      id: 2,
      title: "Custom Quotation",
      value: "custom",
      description: "Create entirely from scratch",
      isActive: false,
    },
  ]);

  const [serviceTypes, setServiceType] = useState<IQuotationCard[]>([
    {
      id: 1,
      title: "Less Container Load (LCL)",
      value: "lcl",
      isActive: false,
      image: "/assets/images/mcs-less-container.svg",
    },
  ]);

  const handleCardClick = (value: string, type: CARD_TYPES) => {
    if (type == CARD_TYPES.QUOTATION_TYPE) {
      const updatedCardData = quotationTypes.map((card) => ({
        ...card,
        isActive: card.value === value ? true : false,
      }));
      formikQuotation?.setFieldValue("quotation_type", value);
      setQuotationTypes(updatedCardData);
    } else {
      const updatedCardData = serviceTypes.map((card) => ({
        ...card,
        isActive: card.value === value ? true : false,
      }));
      formikQuotation?.setFieldValue("service_type", value);
      setServiceType(updatedCardData);
    }
  };

  return (
    <GenerateQuotationComponent
      quotationTypes={quotationTypes}
      serviceTypes={serviceTypes}
      handleCardClick={handleCardClick}
      clicked={clicked}
    />
  );
};

export default GenerateQuotationContainer;
