import React, { useEffect, useState } from "react";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import ContentWrapper from "src/components/layouts/content-wrapper";
import useTitleCase from "src/hooks/useTitleCase";

const ChangePasswordComponent = ({ formik, resetError, handleSubmit }: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const fields = ["current_password", "new_password", "confirm_password"];
  const { titleCasedString, handleInputChange } = useTitleCase();

  useEffect(() => {
    fields.map((fieldName: string) => {
      handleInputChange(fieldName.replace(/_/g, " "), fieldName);
    });
  }, []);

  return (
    <ContentWrapper>
      <div className="grid xl:grid-cols-12">
        <div className="col-span-12 xl:col-span-6">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-1 rounded border border-border p-24"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                formik.submitForm();
              }
            }}
          >
            <div className="flex justify-between border-b border-border pb-24">
              <div>
                <h2 className="text-24 font-bold leading-32 text-gray-800">
                  Change Password
                </h2>
                <h3 className="text-14 font-normal leading-20 text-gray-500-base">
                  Change your password every 30 days to avoid security risks
                </h3>
              </div>
              <div className="mt-8">
                <CustomButton
                  variant={isEdit ? "secondary" : "primary"}
                  block={false}
                  className="px-16 "
                  onClick={() => {
                    formik.resetForm();
                    setIsEdit(!isEdit);
                  }}
                >
                  {isEdit ? "Cancel" : "Edit"}
                </CustomButton>
              </div>
            </div>
            {fields.map((fieldName, index) => (
              <TextInput
                key={fieldName}
                name={fieldName}
                type="password"
                label={titleCasedString[fieldName]}
                placeholder={`Enter ${fieldName.replace(/_/g, " ").toLowerCase()}`}
                value={formik?.values?.[fieldName]}
                showError={Boolean(formik.touched[fieldName])}
                onChange={(e) => {
                  resetError();
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  resetError();
                }}
                errorMessage={formik.errors[fieldName]}
                classes={{
                  containerClassName: `mb-12 ${
                    index === 0 ? "mt-24" : ""
                  } custom-input`,
                  inputClassName: "",
                }}
                required={true}
                disabled={!isEdit}
                {...(index === 2 ? { style: { color: "red" } } : {})}
              />
            ))}
            <div className="mt-8" hidden={!isEdit}>
              <CustomButton
                block={false}
                className="px-16 "
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Save
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ChangePasswordComponent;
