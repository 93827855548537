import React from "react";
import { Dropdown } from "antd";
import { ICustomDropdown } from "./types";
import "./style.css";

const CustomDropdownComponent = ({ items, children }: ICustomDropdown) => {
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <button onClick={(e) => e.preventDefault()}>{children}</button>
    </Dropdown>
  );
};

export default CustomDropdownComponent;
