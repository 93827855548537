import { CONSTANTS } from "src/constants";
import * as yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const currentUserSchema = yup.object().shape({
  first_name: yup.string().trim().required("Please enter first name"),
  role: yup.string().trim().required("Please select role"),
  display_name: yup.string().trim().max(25, "Maximum characters allowed 25"),
  phone: yup
    .string()
    .trim()
    .test("phone", "Invalid phone number", (value) => {
      if (!value) return true; // Allow empty value
      try {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber?.isValid();
      } catch (error) {
        return false;
      }
    }),
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
});
