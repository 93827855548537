import axios from "axios";

import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { CONSTANTS } from "src/constants";
import { ENDPOINTS } from "src/store/endpoints";
import { showToast } from "src/utils";
import dayjs from "dayjs";

export const formatDateQuotation = (dateString: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = months[netherlandsDate.getMonth()];
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();
  const hours = netherlandsDate.getHours() + 1;
  const minutes = netherlandsDate.getMinutes();
  const seconds = netherlandsDate.getSeconds();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zeros to minutes and seconds if they are single digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return dateString
    ? `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`
    : "";
};

export const loginUserApi = () => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        name: "Test",
        email: "test@gmail.com",
      });
    }, 1000);
  });
};

export const checkForEmptyBioUser = (user: any) => {
  if (
    !user?.first_name ||
    !user?.last_name ||
    !user?.dob ||
    !user?.profession ||
    !user?.country
  ) {
    showToast(
      TOASTR_TYPES.ERROR,
      "Please fill the required fields in personal info",
    );
    return true;
  } else if (user?.biography?.length == 0) {
    showToast(
      TOASTR_TYPES.ERROR,
      "You cannot publish the biography as it is currently empty",
    );
    return true;
  } else {
    return false;
  }
};

let timerId: NodeJS.Timeout;

let cancelTokenSource = axios.CancelToken.source();

export const checkEmailExists = (data: any) => {
  clearTimeout(timerId);
  // Cancel the previous request
  cancelTokenSource.cancel();

  // Create a new CancelToken source for the current request
  cancelTokenSource = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    timerId = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + ENDPOINTS.ADMIN.CHECK_EMAIL_EXISTS,
          data,
          {
            cancelToken: cancelTokenSource.token,
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    }, 500);
  });
};

export const formatDataForUpdatePricings = (obj: any) => {
  const data: any = [];

  const mapping: any = {
    arr: "pre-carriage",
    arr1: "origin",
    arr2: "destination",
    arr3: "ocean-freight",
  };

  for (const key in obj) {
    obj[key]?.forEach((item: any) => {
      data.push({
        charge_type: mapping[key],
        sub_charge_type: item.type,
        charges:
          (item.per_rate_basis == "0.00"
            ? item?.charges
            : item.per_rate_basis) * item?.qty,
        rate_basis: item.rate_basis,
        qty: item.qty,
        per_rate_basis:
          item.per_rate_basis == "0.00" ? item?.charges : item.per_rate_basis,
        direction:
          mapping[key] == "origin"
            ? "export"
            : mapping[key] == "destination"
              ? "import"
              : "",
      });
    });
  }

  return data;
};

export const getFilterCountQuotation = (data: any, filterType: any) => {
  let count = 0;
  Array.isArray(data) &&
    data?.forEach((quotation: any) => {
      if (quotation.mode_of_transport === filterType) {
        count++;
      }
    });
  return count;
};

export const convertKgCM = (value: number, unit: any) => {
  let ratio = 2.54;

  if (!unit) {
    ratio = 1 / 2.54;
  }

  const convertedVal = (value * ratio * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertVolumeUnit = (value: number, unit: any) => {
  let ratio = 35.314667;

  if (!unit) {
    ratio = 1 / 35.314667;
  }

  const convertedVal = (value * (1 / ratio) * 10) / 10;

  return naiveRound(convertedVal, 2);
};

export const convertPoundKg = (value: number, unit: any) => {
  if (!unit) {
    //kg to lb
    return naiveRound(value * 2.205);
  }

  return naiveRound((value * 1) / 2.205);
};

export function naiveRound(num: number, decimalPlaces = 2) {
  const p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

export const convertVolume = (value: any) => {
  const calResult = Number.parseFloat(value) / 1000000;

  return calResult.toFixed(3) || "-";
};

export const generateErrorMessage = (
  field: string,
  isValueLimit = false,
  value?: string,
) => {
  if (isValueLimit) {
    return `Max ${field} ${value}`;
  }
  return "Please enter " + field;
};

export const convertDataForPricings = (input: any) => {
  const mapping: { [key: string]: string } = {
    preCarriage: "pre-carriage",
    origin: "origin",
    oceanFreight: "ocean-freight",
    destination: "destination",
  };

  const newObj: { [key: string]: any } = {};
  for (const x in input) {
    newObj[mapping[x]] = input[x].map((e: any) => ({ [e.carriage]: e.amount }));
  }
  return newObj;
};

export const isCustomQuotation = (formikQuotation: any) => {
  return formikQuotation?.values?.quotation_type !== "internal";
};

export const checkErrorCargoItem = (
  errors: any,
  total_cargo_dimensions: boolean,
) => {
  return total_cargo_dimensions
    ? errors?.volume || errors?.weight
    : errors?.height || errors?.length || errors?.width || errors?.weight;
};

export const formatQuotationReference = (data: any) => {
  if (data?.reference_no && data?.uid) {
    return data?.reference_no + "-" + data?.uid;
  } else if (data?.uid) {
    return data?.uid || "-";
  } else {
    return data?.reference_no || "-";
  }
};

export const formatDataForCustomerQuotationExport = (data: any) => {
  return data.map((item: any) => {
    return {
      "Reference No.": formatQuotationReference(item) || "",
      "Creation Date": formatDateQuotation(item?.created_at),
      Origin: ` ${item?.source_port} ,${item?.source_country}`,
      Destination: `${item?.destination_port} ,${item?.destination_country} `,
      MOT: item?.mode_of_transport?.toUpperCase(),
      "Service Type": item?.quotation_type?.toUpperCase(),
      Status: item?.status?.toUpperCase(),
    };
  });
};

export const checkDefaultCustomer = (user: any) => {
  return user?.email === CONSTANTS.DEFAULT_CUSTOMER_EMAIL;
};

export const formatWebsiteUrl = (url: any) => {
  // Check if the URL starts with "https://" or "http://"
  // Trim the URL and check if it is empty or whitespace-only
  const trimmedUrl = url?.trim();
  if (!trimmedUrl) {
    // If empty or whitespace-only, return an empty string
    return "";
  }

  if (!trimmedUrl.startsWith("https://") && !url.startsWith("http://")) {
    // If not, prepend "https://"
    return `https://${url}`;
  }

  // If the URL already starts with "https://" or "http://", return as is
  return url;
};

export const formatDateQuotationRoute = (dateString: string) => {
  // Convert to Netherlands time
  const netherlandsDate = new Date(dateString);
  netherlandsDate.toLocaleString("en-NL", { timeZone: "Europe/Amsterdam" });

  // Extract components for formatting
  const month = netherlandsDate.getMonth();
  const day = netherlandsDate.getDate();
  const year = netherlandsDate.getFullYear();

  return dateString ? `${day}/${month}/${year}` : "";
};

export const roundToTwoDecimalPoints = (input: any) => {
  return input;
};

export const getCurrentDate = () => {
  return dayjs(new Date());
};

export const convertToLowerCaseWithSpaces = (value: string) => {
  return value?.replace(/_/g, " ").toLowerCase() || value;
};

export const removeCommas = (inputString: string): any => {
  return inputString?.replace(/,/g, "") || "";
};

export const roundToTwoDecimalPoint = (input: string | number) => {
  // Convert input to a string if it's not already a string
  const inputStr = typeof input === "number" ? String(input) : input;

  // Extract the numerical part from the input string
  const numberStr = inputStr.replace(/[^\d.]/g, ""); // Remove non-numeric characters

  // Convert the extracted numerical part to a number
  const number = parseFloat(numberStr);

  // Check if the extracted number is valid
  if (isNaN(number)) {
    return NaN; // Return NaN if the extracted number is not valid
  } else if (Number.isInteger(number)) {
    return number; // Return the number unchanged if it's an integer
  } else {
    return Math.round((number + Number.EPSILON) * 100) / 100; // Round to two decimal points otherwise
  }
};

export const objectToQueryParams = (obj: Record<string, any>) => {
  const queryParams = Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== "",
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryParams;
};

export const formatSortingPayload = (payload: any) => {
  return Object.keys(payload)?.length > 0 ? JSON.stringify(payload) : null;
};
