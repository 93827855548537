import * as yup from "yup";

export const marginGroupSchema = yup.object().shape({
  name: yup.string().trim().required("Please enter name"),
  margin_percentage: yup
    .string()
    .trim()
    .required("Please enter margin percentage"),
  customerId: yup
    .array()
    .of(yup.string())
    .min(1, "Please add at least one customer")
    .required("Please add customers"),
});
