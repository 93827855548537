import React, { useState } from "react";
import { Icon } from "src/components/atoms/icons";

interface FilterInputsProps {
  values: any;
  setFiltersData: any;
}

const FilterInputs: React.FC<FilterInputsProps> = ({
  values,
  setFiltersData,
}) => {
  const [validitySuffix, setValiditySuffix] = useState<React.ReactNode>(
    <Icon.McsIcDownArrow />,
  );
  const [typeSuffix, setTypeSuffix] = useState<React.ReactNode>(
    <Icon.McsIcDownArrow />,
  );
  const [statusSuffix, setStatusSuffix] = useState<React.ReactNode>(
    <Icon.McsIcDownArrow />,
  );

  const handleValidityChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      validity: value || null,
    }));
    setValiditySuffix(value ? "" : <Icon.McsIcDownArrow />);
  };

  const handleTypeChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      type: value || null,
    }));
    setTypeSuffix(value ? "" : <Icon.McsIcDownArrow />);
  };

  const handleStatusChange = (value: string) => {
    setFiltersData((prev: any) => ({
      ...prev,
      status: value || null,
    }));
    setStatusSuffix(value ? "" : <Icon.McsIcDownArrow />);
  };

  const handleSuffixClick = (type: string) => {
    switch (type) {
      case "validity":
        handleValidityChange("");
        break;
      case "type":
        handleTypeChange("");
        break;
      case "status":
        handleStatusChange("");
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex w-full gap-3 xxl:w-auto">
      <div className="relative flex-1">
        <div className="absolute right-[8px] top-[8px] flex items-center">
          <button onClick={() => handleSuffixClick("validity")}>
            <i hidden={values.validity === null}>
              <Icon.McsIcCross fill={"#f15046"} width="24" height="24" />
            </i>
          </button>
        </div>
      </div>
      <div className="relative flex-1">
        <div className="absolute right-[8px] top-[8px] flex items-center">
          <button onClick={() => handleSuffixClick("type")}>
            <i hidden={values.type === null}>
              <Icon.McsIcCross fill={"#f15046"} width="24" height="24" />
            </i>
          </button>
        </div>
      </div>
      <div className="relative flex-1">
        <div className="absolute right-[8px] top-[8px] flex items-center">
          <button onClick={() => handleSuffixClick("status")}>
            <i hidden={values.status === null}>
              <Icon.McsIcCross fill={"#f15046"} width="24" height="24" />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterInputs;
