import React from "react";
import { Steps } from "antd";
import { Icon } from "src/components/atoms/icons";
import "./style.css";
import { findIconByValue } from "src/utils";
const QuotationVerticalStepsComponent = ({
  item,
  quotItem,
}: {
  item: any;
  quotItem: any;
}) => {
  const icon = findIconByValue(item.mode_of_transport);

  let items: any = [
    {
      title: `(${item?.source_port}), ${item?.source_country}`,
    },
    {
      className: "custom-dot",
      title: !icon ? <Icon.McsIcShip /> : icon.icon,
    },
    {
      title: `(${item?.destination_port}), ${item?.destination_country}`,
    },
  ];

  if (quotItem.isTransshipment) {
    items = [
      {
        title: (
          <span className="font-semibold">
            ({item?.source_port}), {item?.source_country}
          </span>
        ),
      },
      {
        className: "custom-dot",
        title: !icon ? <Icon.McsIcShip /> : icon.icon,
      },
      {
        title: (
          <div className="font-semibold">
            {quotItem?.Transshipment}
            {/* <span className="font-normal"> Transshipment</span> */}
          </div>
        ),
      },
      {
        className: "custom-dot",
        title: !icon ? <Icon.McsIcShip /> : icon.icon,
      },
      {
        className: "hidden",
        title: (
          <span className="font-semibold">
            ({item?.destination_port}), {item?.destination_country}
          </span>
        ),
      },
    ];
  }

  return (
    <div>
      <Steps
        progressDot
        direction="vertical"
        size="small"
        className="custom-vertical-steps"
        items={items}
      />
    </div>
  );
};

export default QuotationVerticalStepsComponent;
