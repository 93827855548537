import parsePhoneNumberFromString from "libphonenumber-js";
import { CONSTANTS } from "src/constants";
import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string().trim().required("Please enter name"),
  city: yup.string().trim().required("Please enter city"),
  country: yup.string().trim().required("Please enter country"),
  contactPersonPhoneNumber: yup
    .string()
    .trim()
    .test("phone-number", "Invalid phone number", (value) => {
      if (!value) return true; // Allow empty value
      try {
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber?.isValid();
      } catch (error) {
        return false;
      }
    }),
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
  contactPersonEmail: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(CONSTANTS.VALIDATIONS.EMAIL, "Invalid email address"),
});
