import React, { useEffect } from "react";
import { ICustomModal } from "./custom-modal.types";
import { Modal } from "antd";
import "./custom-modal.css";
import { Icon } from "src/components/atoms/icons";

const CustomModalComponent = ({
  children,
  title,
  isOpen,
  toggle,
  okText,
  cancelText,
  icon,
  maskClosable,
  width,
  innerClass = "",
  footer,
}: ICustomModal) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  return (
    <Modal
      title={
        <>
          <div className="flex items-center gap-2">
            {icon && (
              <span
                className="modal-icon cursor-pointer p-4"
                onClick={toggle}
              ></span>
            )}{" "}
            {/* Render the icon if provided */}
            <span className="title"> {title}</span>
          </div>
          <div className="cursor-pointer" onClick={toggle}>
            <Icon.McsIcModalClose fill="#212e3e" />
          </div>
        </>
      }
      centered
      open={isOpen}
      onOk={toggle}
      onCancel={toggle}
      className={`custom-modal ${innerClass}`}
      width={width}
      okText={okText}
      cancelText={cancelText}
      maskClosable={maskClosable}
      getContainer={false}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default CustomModalComponent;
