import React from "react";
import { Link } from "react-router-dom";

import CustomModal from "src/components/molecules/custom-modal";
import { Icon } from "src/components/atoms/icons";
interface ModelProps {
  isAddModalOpen: any;
  handleCloseAddModal: () => void;
}
export default function PopModal({
  isAddModalOpen = false,
  handleCloseAddModal,
}: Readonly<ModelProps>) {
  return (
    <CustomModal
      isOpen={isAddModalOpen}
      maskClosable={true}
      toggle={handleCloseAddModal}
      width={1010}
      innerClass="bg-white "
      footer={<>&nbsp;</>}
      title={""}
    >
      <div className=" font-medium">
        <div className="">
          <div className="flex items-center justify-between border-b-[1px] border-blue-link pb-24">
            <p className=" text-18 font-semibold leading-18 text-dark-indigo">
              Quotation Details
            </p>
            <button onClick={handleCloseAddModal}>
              <Icon.McsIcModalClose fill="black" />
            </button>
          </div>
          <table className="mb-10 mt-24 flex w-full flex-col gap-1 pt-24">
            <tr className=" grid  grid-cols-4 text-14 ">
              <td>Booking Reference no.</td>
              <td>Creating data</td>
              <td>Status</td>
              <td>Price</td>
            </tr>
            <tr className="grid grid-cols-4 text-16">
              <td>001</td>
              <td>23/10/2023</td>
              <td>Germany</td>{" "}
              <td className="w-fit  rounded-2xl bg-success-50 px-8 py-2 text-12 font-medium leading-18 text-success-700">
                Booked
              </td>
              <td>EUR 300</td>
            </tr>
          </table>
          <div className="my-24">
            <p className="text-14">Payment Receipt</p>
            <Link
              className="text-16 text-blue-link underline underline-offset-4"
              to={""}
            >
              Download pdf
            </Link>
          </div>
        </div>
        <div className="">
          <p className="border-b-[1px]  border-blue-link pb-24 text-18 font-semibold leading-18 text-dark-indigo">
            Shipper Details
          </p>
          <table className="mb-10 mt-24 flex w-full flex-col gap-1">
            <tr className=" grid w-full grid-cols-4 gap-3 text-14  ">
              <td>Booking Summary</td>
              <td>Shipper Name</td>
              <td>PO# or Shipper Reference </td>
              <td>Contact Person</td>
            </tr>
            <tr className=" grid w-full grid-cols-4 gap-3 text-16 ">
              <td>Pillow office chairs</td>
              <td>SeaMasters Shipping</td>
              <td>-</td>
              <td>Manager</td>
            </tr>
          </table>
          <table className="mb-10 mt-24 flex w-full flex-col gap-1">
            <tr className=" grid w-full grid-cols-4 gap-3 text-14 ">
              <td>Phone Number</td>
              <td>Additional Information</td>
              <td>Address</td>
              <td>Email</td>
            </tr>
            <tr className=" grid w-full grid-cols-4 gap-3 text-16">
              <td>+31 6 1234 5678</td>
              <td>-</td>
              <td>
                123 Main Street <br />
                Amsterdam, NL
              </td>
              <td>Seamasters@shippers.com</td>
            </tr>
          </table>
          <div className="my-24">
            <p className="text-14">Uploaded pdf</p>
            <Link
              className="text-16 text-blue-link underline underline-offset-4"
              to={""}
            >
              File 123 pdf
            </Link>
          </div>
        </div>
        <div className="">
          <p className="border-b-[1px]  border-blue-link pb-24 text-18 font-semibold leading-18 text-dark-indigo">
            Packege Details
          </p>
          <table className="mb-10 mt-24 flex w-full flex-col gap-1">
            <tr className=" grid w-full grid-cols-4 gap-3 text-14 ">
              <td>Carries</td>
              <td>Origin</td>
              <td>Destination</td>
              <td>Service Type</td>
            </tr>
            <tr className=" grid w-full grid-cols-4 gap-3 text-16 ">
              <td>OOCL</td>
              <td>Overschiestraat 182, Amsterdam,NL</td>
              <td>Dubai</td>
              <td>FCL</td>
            </tr>
          </table>
          <table className="mb-10 mt-24 flex w-full flex-col gap-1">
            <tr className=" grid w-full grid-cols-3 gap-3 text-14  ">
              <td>Quantity</td>
              <td>Weight</td>
              <td>Volume</td>
            </tr>
            <tr className=" grid w-full grid-cols-3 gap-3 text-16">
              <td>3</td>
              <td>200kg</td>
              <td>
                4.3M<sup>3</sup>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </CustomModal>
  );
}
