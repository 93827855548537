import React, { useEffect, useRef } from "react";
import { Icon } from "src/components/atoms/icons";
import useDropdownToggle from "src/hooks/useCustomDropdown";
import { Link } from "react-router-dom";
import LogoutButton from "src/components/atoms/logout-button";
import { handleDisplayNameLength } from "src/utils";
import useUser from "src/hooks/useUser";

const ProfileDropDown = () => {
  const { isOpen, toggleDropdown, closeDropdown } = useDropdownToggle(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const user = useUser();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="order-1 ml-auto md:order-2">
      <div className="flex items-center gap-6">
        <div className="h-[32px] w-[1px] bg-border"></div>
        <button
          className="relative flex items-center gap-3"
          onClick={toggleDropdown}
        >
          <div className="profile-pic relative flex items-center gap-3">
            <span className="text-dark truncate text-14 font-normal leading-20">
              <div className="flex h-[30px] shrink-0 items-center gap-2 rounded-full font-semibold text-blue-900">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.625 26.25C5.625 26.25 3.75 26.25 3.75 24.375C3.75 22.5 5.625 16.875 15 16.875C24.375 16.875 26.25 22.5 26.25 24.375C26.25 26.25 24.375 26.25 24.375 26.25H5.625ZM15 15C16.4918 15 17.9226 14.4074 18.9775 13.3525C20.0324 12.2976 20.625 10.8668 20.625 9.375C20.625 7.88316 20.0324 6.45242 18.9775 5.39752C17.9226 4.34263 16.4918 3.75 15 3.75C13.5082 3.75 12.0774 4.34263 11.0225 5.39752C9.96763 6.45242 9.375 7.88316 9.375 9.375C9.375 10.8668 9.96763 12.2976 11.0225 13.3525C12.0774 14.4074 13.5082 15 15 15Z"
                    fill="#004678"
                  />
                </svg>
                {handleDisplayNameLength(user?.display_name ?? user?.name)}
              </div>
            </span>
          </div>

          <div
            className={`dropdown-wrapper absolute right-0 top-[100%] z-40 min-w-[264px] sm:mt-30 md:mt-0 ${
              isOpen ? "block" : "hidden"
            }`}
          >
            <div className="rounded-lg border border-gray-200 bg-white shadow-lg">
              <div className="border-b border-gray-200">
                <div className="flex items-center gap-3 px-16 py-12">
                  <div className="h-[40px] w-[40px] shrink-0 rounded-full">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.625 26.25C5.625 26.25 3.75 26.25 3.75 24.375C3.75 22.5 5.625 16.875 15 16.875C24.375 16.875 26.25 22.5 26.25 24.375C26.25 26.25 24.375 26.25 24.375 26.25H5.625ZM15 15C16.4918 15 17.9226 14.4074 18.9775 13.3525C20.0324 12.2976 20.625 10.8668 20.625 9.375C20.625 7.88316 20.0324 6.45242 18.9775 5.39752C17.9226 4.34263 16.4918 3.75 15 3.75C13.5082 3.75 12.0774 4.34263 11.0225 5.39752C9.96763 6.45242 9.375 7.88316 9.375 9.375C9.375 10.8668 9.96763 12.2976 11.0225 13.3525C12.0774 14.4074 13.5082 15 15 15Z"
                        fill="#004678"
                      />
                    </svg>
                  </div>
                  <div>
                    <div className="max-w-[170px] truncate text-left text-14 font-semibold leading-20 text-blue-900">
                      {user?.display_name || user?.name}
                    </div>
                    <div className="max-w-[170px] truncate text-left text-14 font-normal leading-20 text-gray-600">
                      {user?.email}
                    </div>
                  </div>
                </div>
              </div>
              <ul className="py-4">
                {location.href.includes("admin") && (
                  <li>
                    <Link
                      to="/admin/settings"
                      className="flex items-center gap-2 py-11 pl-16 pr-16 transition duration-[0.4s] hover:hover:bg-gray-300"
                    >
                      <Icon.McsIcProfileDropdownUserManagement />
                      <span className="whitespace-nowrap text-14 font-medium leading-20 text-gray-700">
                        Settings
                      </span>
                    </Link>
                  </li>
                )}

                <li>
                  <LogoutButton />
                </li>
              </ul>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ProfileDropDown;
