import React, { useEffect, useState } from "react";
import CustomDatatable from "src/components/organisms/datatable/elements";
import usePagination from "src/hooks/usePagination";
import { useGetBookingsMutation } from "src/services/api-service/quotations";
import { getColumns } from "./columns";
import Filters from "./filters";
import { useNavigate } from "react-router-dom";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { setLoading } from "src/store/features/alerts";
import { ENDPOINTS } from "src/store/endpoints";
import { useAppDispatch } from "src/store/hook";
import useUser from "src/hooks/useUser";
import { formatQuotationReference } from "src/helpers";

export default function BookingDatatable() {
  const navigate = useNavigate();
  const [getBookings] = useGetBookingsMutation();
  const columns = getColumns();
  const baseUrl = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const user = useUser();

  const downloadPdf = async (data: any) => {
    const downloadBookingEndpoint =
      ENDPOINTS.ADMIN.DOWNLOAD_BOOKING + data?.id + "/pdf";
    try {
      dispatch(setLoading(true));
      const token = user?.access_token;
      const downloadUrl = baseUrl + downloadBookingEndpoint;
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? "";
        const filename = `${formatQuotationReference(data?.main_quotation)}_booking.pdf`;
        const message =
          response.headers.get("message") ??
          "The Pdf has been successfully downloaded";

        const blob = new Blob([await response.blob()], {
          type: contentType,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast(TOASTR_TYPES.SUCCESS, message);
      } else {
        showToast(TOASTR_TYPES.ERROR, `Enable to download`);
      }
    } catch (error: any) {
      showToast(TOASTR_TYPES.ERROR, `Enable to download`);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [filtersData, setFiltersData] = useState({
    mot: null,
    type: null,
    status: null,
    service_type: [],
  });
  const [columnDefs] = useState(columns);

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
  } = usePagination({
    key: "booking-datatable",
    mutation: getBookings,
    filtersData,
  });

  const [columnFilter, setColumnFilter] = useState<string[]>([]);

  const [columnDefsFilter] = useState([
    ...columns.map((column) => column.headerName),
  ]);

  useEffect(() => {
    setColumnFilter([...columnDefsFilter]);
  }, []);

  return (
    <CustomDatatable
      title={"Bookings"}
      description={`${pagination.total} Total Booking${pagination.total > 1 ? "s" : ""}`}
      className="date-picker"
      data={data}
      columns={columnDefs.filter((column) =>
        columnFilter.includes(column.headerName),
      )}
      showHeader={true}
      showFooter={true}
      pagination={pagination}
      setPagination={setPagination}
      handleSearch={handleSearch}
      handlePageChange={handlePageChange}
      handleItemsPerPageChange={handleItemsPerPageChange}
      rowDraggable={false}
      onCellClicked={(data: any) => {
        if (data?.colDef?.field === "download_pdf") {
          downloadPdf(data?.data);
        } else {
          navigate(`/bookings/details/${data.data.id}`);
        }
      }}
      clearSearch={clearSearch}
      filters={
        <Filters
          columnOption={columnDefsFilter}
          sendFilteredColumns={setColumnFilter}
          values={filtersData}
          setFiltersData={setFiltersData}
          isShow={false}
        />
      }
      button={
        <Filters
          columnOption={columnDefsFilter}
          sendFilteredColumns={setColumnFilter}
          values={filtersData}
          setFiltersData={setFiltersData}
          isShow={true}
        />
      }
    />
  );
}
