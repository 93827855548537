import React from "react";
import CustomButton from "src/components/atoms/button";
import { ISettingHeader } from "./setting-header.types";

const SettingsHeaderComponent = ({
  headingText,
  subheadingText,
  showButton = true,
  buttonText = "Edit Information",
  variant = "primary",
  onClick,
}: ISettingHeader & { showButton?: boolean }) => {
  return (
    <div className="mb-24 items-center justify-between  sm:flex">
      <div className="flex flex-col gap-1">
        <h2 className="text-24 font-bold leading-32 text-gray-800 ">
          {headingText}
        </h2>
        <p className="text-14 font-normal leading-20 text-gray-500-base">
          {subheadingText}
        </p>
      </div>
      {showButton && (
        <div className="mt-10 lg:mt-0">
          <CustomButton variant={variant} onClick={onClick}>
            {buttonText}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default SettingsHeaderComponent;
