import React, { useState, useEffect } from "react";
import Label from "../label";
import ErrorLabel from "../error-label";

import { Select } from "antd";
import { Icon } from "../icons";
import "./style.css";
import { ICountryPortPicker, ICountryPort } from "src/types";

const PortSelecterComponent = ({
  id,
  name,
  label,
  classes,
  placeholder,
  required = false,
  errorMessage = "",
  showError,
  onSelect,
  ports,
  value,
  showSearch,
  allowCustomValue = true,
}: ICountryPortPicker) => {
  const [searchValue, setSearchValue] = useState(value ?? "");

  useEffect(() => {
    setSearchValue(value ?? "");
  }, [value]);

  // Function to filter ports based on search value including aliases
  const filterPorts = (value: string) => {
    return ports?.filter(
      (port: ICountryPort) =>
        port?.name
          ?.toLowerCase()
          ?.includes(showSearch ? value.toLowerCase() : "") ||
        port.locode
          ?.toLowerCase()
          .includes(showSearch ? value.toLowerCase() : "") ||
        (port.aliases &&
          JSON.parse(port.aliases).some((alias: string) =>
            alias.toLowerCase().includes(showSearch ? value.toLowerCase() : ""),
          )),
    );
  };

  const style = !allowCustomValue
    ? { caretColor: "transparent", cursor: "pointer" }
    : {};

  return (
    <div className={classes?.containerClassName}>
      {label && <Label id={id || name} name={label} required={required} />}

      <div className="portSelectWrapper">
        <div className="prefix-icon-wrapper">{<Icon.McsIcMapPin />}</div>
        <Select
          style={style}
          popupClassName="!bg-primary-50"
          placeholder={placeholder}
          size="large"
          className="w-full"
          value={searchValue}
          showSearch={true}
          optionLabelProp="label"
          onChange={(value: any) => {
            onSelect(value);
            setSearchValue(value);
          }}
          onSearch={(value: string) => {
            setSearchValue(value);
            // Call the mutation function with the search value
            filterPorts(showSearch ? value : "")?.map((port: ICountryPort) => ({
              value: port.locode,
              label: (
                <div className="flex flex-col gap-2">
                  <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                    {port.name}
                  </span>
                  <span>{`${port.name} (${port.locode})`}</span>
                </div>
              ),
            }));
          }}
        >
          {filterPorts(searchValue)?.map((item: any) => (
            <option value={item.locode} key={item.locode} label={item.locode}>
              <div className="flex flex-col gap-2">
                <span className="!text-12 !font-medium !leading-18 !text-gray-700">
                  {item?.name || ""}
                </span>
                <span>{`${item?.name || ""} ${item.locode}`}</span>
              </div>
            </option>
          ))}
        </Select>
      </div>

      {showError && (
        <ErrorLabel
          message={errorMessage}
          className={classes?.errorLabelClass}
        />
      )}
    </div>
  );
};

export default PortSelecterComponent;
