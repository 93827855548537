import React, { useEffect, useLayoutEffect, useState } from "react";
import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import { useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextInput from "src/components/atoms/text-input";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import CustomSelect from "src/components/atoms/custom-select";
import {
  useDeleteAdminMutation,
  useGetAdminsListMutation,
} from "src/services/api-service/admin-users";
import useLoadingError from "src/hooks/useLoadingError";
import { IAdminCrudForm, ROLES, ROLES_OPTIONS } from "./datatable.types";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import useCheckDisabled from "src/hooks/useCheckDisabled";

const Datatable = ({
  formik,
  resetError,
  getId,
  closeModal,
  setCloseModal,
  reFetchData,
}: IAdminCrudForm) => {
  const { startLoading, stopLoading } = useLoadingError();

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const user = useAppSelector(userSelector);
  const [storedData, setStoredData] = useState<any>(null);
  const { isDisabled, handleInputChange } = useCheckDisabled();

  const handleOpenAddModal = () => {
    setUpdateId("");
    getId("");
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    formik.resetForm();
    setAddModalOpen(false);
  };

  const [getAdminUsers] = useGetAdminsListMutation<any>();
  const [deleteAdminUser] = useDeleteAdminMutation<any>();

  const convertToTitleCase = (inputString: string) => {
    return (
      inputString
        ?.toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ") || ""
    );
  };

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "admin-user-management-datatable",
    mutation: getAdminUsers,
  });

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleColumnChange = (selectedValues: string[]) => {
    // Handle column change logic if needed
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      startLoading();
      updateId &&
        deleteAdminUser(body)
          .unwrap()
          .then((data: any) => {
            console.log(data);
            showToast(
              TOASTR_TYPES.SUCCESS,
              data?.message || data?.data?.message || data?.original?.message,
            );
            fetchData({ currentPage: pagination?.currentPage, query: "" });
          });
    } finally {
      stopLoading();
    }
    setDeleteModalOpen(false);
  };

  const [roleOptions, setRoleOptions] = useState<any[]>(ROLES_OPTIONS);

  const filterOptionsByCurrentRole = () => {
    const options = ROLES_OPTIONS.filter((option) =>
      option.allowed.includes(user?.role),
    );
    setRoleOptions(options);
  };

  useEffect(() => {
    filterOptionsByCurrentRole();
  }, []);

  const getDataById = async () => {
    if (updateId) {
      const selectedData = data.find((item) => item.id === updateId);
      console.log(selectedData);
      if (selectedData) {
        selectedData?.name && formik.setFieldValue("name", selectedData?.name);
        selectedData?.email &&
          formik.setFieldValue("email", selectedData?.email);
        selectedData?.role && formik.setFieldValue("role", selectedData?.role);
        formik.unregisterField("password");
        getId(updateId);
        setStoredData(selectedData);
        setAddModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (!isDeleteModalOpen) {
      updateId && getDataById();
    }
  }, [updateId]);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      !isAddModalOpen && setUpdateId("");
    }
  }, [isAddModalOpen]);

  const handleCellClick = (event: any) => {
    setUpdateId(event?.data?.id);
  };

  const handleOpenEditModal = () => {
    console.log("Handle open edit modal");
  };

  const handleDisabledEditModal = (data: any) => {
    let isDisabled = true;
    if (user?.role === ROLES.SUPER_ADMIN) {
      isDisabled = false;
    }
    if (
      user?.role === ROLES.ADMIN &&
      convertToTitleCase(data?.data?.role) === convertToTitleCase(ROLES.ADMIN)
    ) {
      isDisabled = true;
    }
    if (
      user?.role === ROLES.ADMIN &&
      convertToTitleCase(data?.data?.role) === convertToTitleCase(ROLES.USER)
    ) {
      isDisabled = false;
    }
    if (user?.role === ROLES.USER) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const [columnDefs] = useState<any[]>(
    columns(
      { handleOpenDeleteModal },
      { handleDisabledEditModal },
      { handleOpenEditModal },
    ),
  );

  useEffect(() => {
    reFetchData &&
      fetchData({ currentPage: pagination?.currentPage, query: "" });
  }, [reFetchData]);

  useEffect(() => {
    handleCloseAddModal();
    setCloseModal(false);
  }, [closeModal]);

  useLayoutEffect(() => {
    const requiredKeys = ["name", "email", "role"];
    const requiredKeysWithPassword = ["name", "email", "role", "password"];
    const values = {
      name: formik.values.name,
      email: formik.values.email,
      role: formik.values.role,
    };
    const delayDebounceFn = setTimeout(() => {
      !updateId &&
        handleInputChange(
          formik.values,
          requiredKeysWithPassword,
          storedData,
          updateId,
        );
      updateId && handleInputChange(values, requiredKeys, storedData, updateId);
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [formik.values, isDisabled, storedData, updateId]);

  return (
    <>
      <CustomDatatable
        title={"Admin Users"}
        description={`${pagination.total} Total User${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        placeholder="Search by name and role"
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onRowClicked={() => {
          console.log("row clicked");
        }}
        onCellClicked={(event: any) => handleCellClick(event)}
        handleColumnChange={handleColumnChange}
        button={
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center">
              <CustomButton
                variant="primary-icon-btn"
                onClick={handleOpenAddModal}
              >
                <div className="flex items-center gap-2">
                  <i className="shrink-0">
                    <Icon.McsIcIconPlus />
                  </i>
                  <span>Add Admin Users</span>
                </div>
              </CustomButton>
              {/* Add admin user modal */}
              <CustomModal
                title={(updateId ? "Edit" : "Add") + " Admin User"}
                isOpen={isAddModalOpen}
                toggle={handleCloseAddModal}
                maskClosable={false}
                icon={<Icon.McsIcChevronBack />}
                width={412}
                innerClass="custom-common-modal"
                closeModal={handleCloseAddModal}
                footer={
                  <div className="w-full justify-end gap-4 lg:flex">
                    <div className="order-1 lg:order-2">
                      <CustomButton
                        variant="primary"
                        htmlType="submit"
                        disabled={isDisabled}
                        className="w-full"
                      >
                        Save
                      </CustomButton>
                    </div>
                    <div className="order-2 mt-10 lg:order-1 lg:mt-0">
                      <CustomButton
                        variant="secondary"
                        onClick={handleCloseAddModal}
                        className="w-full"
                      >
                        Cancel
                      </CustomButton>
                    </div>
                  </div>
                }
              >
                <div className="flex flex-col gap-4">
                  <div>
                    <TextInput
                      name="name"
                      type="text"
                      label={
                        (convertToTitleCase(formik?.values?.role) &&
                          convertToTitleCase(formik?.values?.role) + " ") +
                        "Name"
                      }
                      placeholder="Enter Full Name"
                      value={formik.values.name}
                      showError={Boolean(formik.touched.name)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.name}
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      required={true}
                    />
                  </div>
                  <div>
                    <TextInput
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      disabled={!!updateId}
                      value={formik.values.email}
                      showError={Boolean(formik.touched.email)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.email}
                      classes={{
                        containerClassName: "custom-input",
                        inputClassName: "",
                      }}
                      required={true}
                    />
                  </div>
                  <div>
                    <CustomSelect
                      label="Role"
                      name="role"
                      placeholder="Select an option"
                      value={formik.values.role}
                      showError={Boolean(formik.touched.role)}
                      onChange={(e: any) => {
                        resetError();
                        formik.setFieldValue("role", e);
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.role}
                      options={roleOptions}
                      allowClear
                      required={true}
                      // disabled={true}
                    />
                  </div>
                  <div hidden={Boolean(updateId)}>
                    <TextInput
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Enter Password"
                      value={formik.values.password}
                      showError={Boolean(formik.touched.password)}
                      onChange={(e) => {
                        resetError();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        resetError();
                      }}
                      errorMessage={formik.errors.password}
                      classes={{ containerClassName: "custom-input" }}
                      required={Boolean(updateId)}
                    />
                  </div>
                </div>
              </CustomModal>
              {/* Add admin user modal end */}
            </div>
          </form>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this admin user?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </>
  );
};

export default Datatable;
