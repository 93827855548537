import { IResponse } from "../../types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

// Define a type for the postcode
interface Postcode {
  zip_code: string;
  area_local: string;
  area_english: string;
  country_code: string;
}

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPostcodesList: build.mutation<Postcode[], Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.POSTCODE_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      transformResponse: (res: IResponse) => {
        return res.data as Postcode[];
      },
    }),
  }),
});

export const { useGetPostcodesListMutation } = APIS;
