import { objectToQueryParams } from "src/helpers";
import { IResponse } from "../../types";
import { rootApi } from "src/services/api";

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.mutation<IResponse, Partial<any>>({
      query: ({ endpoint, ...body }) => {
        return {
          url: endpoint + `?${objectToQueryParams(body)}`,
          method: "GET",
        };
      },
      transformResponse: (res: IResponse) => {
        return res?.data;
      },
    }),
  }),
});
export const { useGetLocationsMutation } = APIS;
