import React from "react";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { IResetPasswordForm } from "./reset-password-form.types";
import ErrorLabel from "src/components/atoms/error-label";
import Recaptcha from "src/components/molecules/reacaptcha";
import { Icon } from "src/components/atoms/icons";
import FormHeader from "../../elements/form-header";

const ResetPasswordForm = ({
  formik,
  error,
  resetError,
  loading,
  setRecaptcha,
  isVerified,
}: IResetPasswordForm) => {
  return (
    <div>
      <FormHeader
        title="Create new password"
        description="Fill all the form below to create your new password"
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-32">
          <TextInput
            name="password"
            type="password"
            label="New Password"
            placeholder="Enter your password"
            classes={{ containerClassName: "mb-12" }}
            value={formik.values.password}
            showError={Boolean(formik.touched.password)}
            onChange={(e) => {
              resetError();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.password}
            autoFocus={true}
            required={true}
            prefix={<Icon.McsIcPasswrod />}
            // this is eye icon
            // suffix={<Icon.McsIcEye />}
          />
          <TextInput
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            placeholder="Enter your password"
            classes={{ containerClassName: "mb-12" }}
            value={formik.values.confirmPassword}
            showError={Boolean(formik.touched.confirmPassword)}
            onChange={(e) => {
              resetError();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.confirmPassword}
            required={true}
            prefix={<Icon.McsIcPasswrod />}
            // this is eye icon
            // suffix={<Icon.McsIcEye />}
          />
          {Boolean(error) && <ErrorLabel message={error} />}

          <Recaptcha
            setRecaptcha={setRecaptcha}
            showError={formik.submitCount > 0 && !isVerified}
          />
        </div>
        <CustomButton className="w-full" htmlType="submit" loading={loading}>
          Save
        </CustomButton>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
