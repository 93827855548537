import * as yup from "yup";

export const costGroupSchema = yup.object().shape({
  name: yup.string().trim().required("Please enter name"),
  newCustomerIds: yup
    .array()
    .of(yup.string())
    .min(1, "Please add at least one customer")
    .required("Please add customers"),
});
