import React, { useEffect, useState } from "react";

import { columns } from "./columns";
import usePagination from "src/hooks/usePagination";
import CustomDatatable from "src/components/organisms/datatable/elements";
import CustomButton from "src/components/atoms/button";
import { Icon } from "src/components/atoms/icons";
import ConfirmDeleteComponent from "src/components/organisms/confirm-delete/confirm-delete.component";
import {
  useDeleteUserMutation,
  useDownloadAllCustomersMutation,
  useGetUsersListMutation,
} from "src/services/api-service/user";
import { TOASTR_TYPES } from "src/components/atoms/toast-container/types";
import { showToast } from "src/utils";
import { useNavigate } from "react-router-dom";
import AddCustomerContainer from "./add-customer/add-customer.container";

const Datatable = () => {
  const [getListMutation] = useGetUsersListMutation<any>();
  const [deleteItemMutation] = useDeleteUserMutation<any>();
  const [downloadAllCustomers, { isLoading }] =
    useDownloadAllCustomersMutation();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [reFetchData, setReFetchData] = useState(false);
  const navigate = useNavigate();

  const {
    pagination,
    setPagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "user-management-datatable",
    mutation: getListMutation,
  });

  useEffect(() => {
    reFetchData &&
      fetchData({ currentPage: pagination?.currentPage, query: "" });
  }, [reFetchData]);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      updateId && setAddModalOpen(true);
    }
  }, [updateId, isAddModalOpen]);

  const handleCellClick = (event: any) => {
    let params;
    let queryString = "";
    if (event?.colDef?.field === "total_users") {
      params = {
        id: event?.data?.customerid,
        name: event?.data?.customername,
      };
      queryString = new URLSearchParams(params).toString();
      navigate("/admin/customer-management/users?" + queryString);
    }
    if (event?.colDef?.field === "total_quotations") {
      params = {
        id: event?.data?.customerid,
        name: event?.data?.customername,
      };
      queryString = new URLSearchParams(params).toString();
      navigate("/admin/customer-management/quotations?" + queryString);
    }
    if (event?.colDef?.field === "booked_quotations") {
      params = {
        id: event?.data?.customerid,
        name: event?.data?.customername,
      };
      queryString = new URLSearchParams(params).toString();
      navigate("/admin/customer-management/bookings?" + queryString);
    }
    setUpdateId(event?.data?.customerid);
  };

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleOpenEditModal = (id: any) => {
    setUpdateId(id);
  };

  const handleCloseAddModal = () => {
    setUpdateId("");
    setAddModalOpen(false);
  };

  const handleOpenDeleteModal = (id: any) => {
    setUpdateId(id);
    setDeleteModalOpen(true);
  };

  const [columnDefs] = useState<any[]>(
    columns({ handleOpenDeleteModal }, { handleOpenEditModal }),
  );

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = () => {
    const body = {
      id: updateId,
    };
    try {
      if (updateId) {
        deleteItemMutation(body)
          .unwrap()
          .then((data: any) => {
            showToast(
              TOASTR_TYPES.SUCCESS,
              data?.original?.message || data?.message,
            );
            fetchData({ currentPage: pagination?.currentPage, query: "" });
          });
      }
    } catch (err) {
      console.log(err);
    }
    setDeleteModalOpen(false);
  };

  const handleExportAllCustomer = async () => {
    try {
      const response = await downloadAllCustomers().unwrap();

      const fields_to_include = [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        {
          key: "customer_user",
          label: "Users",
          special: (value: any) => (Array.isArray(value) ? value.length : "-"),
        },
        {
          key: "main_quotations",
          label: "Quotations",
          special: (value: any) => (Array.isArray(value) ? value.length : "-"),
        },
        {
          key: "margin_group",
          label: "Group",
          special: (value: any) => value?.name || "-",
        },
        { key: "booked_quotations_count", label: "Bookings" },
        { key: "country", label: "Country" },
        {
          key: "updated_at",
          label: "Latest Activity",
          special: (value: any) =>
            value ? new Date(value).toLocaleString() : "-",
        },
      ];

      // Preprocess data
      const preprocessedData = response.data.map(
        (item: Record<string, any>) => {
          const filteredItem: Record<string, any> = {};
          fields_to_include.forEach(({ key, special }) => {
            const value = special ? special(item[key]) : item[key];
            filteredItem[key] = value;
          });
          return filteredItem;
        },
      );

      // Generate CSV content
      const csvContent =
        fields_to_include.map(({ label }) => `"${label}"`).join(",") +
        "\n" +
        preprocessedData
          .map((item: any) =>
            fields_to_include
              .map(({ key }) => `"${String(item[key] || "-")}"`)
              .join(","),
          )
          .join("\n");

      // Trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Customers.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Failed to download customers", err);
    }
  };

  return (
    <>
      <CustomDatatable
        title={"Customers"}
        description={`${pagination.total} Total Customer${pagination.total > 1 ? "s" : ""}`}
        className="date-picker"
        data={data}
        columns={columnDefs}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        setPagination={setPagination}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        rowDraggable={false}
        onCellClicked={(event: any) => handleCellClick(event)}
        button={
          <div className="flex items-center gap-3">
            <CustomButton
              disabled={isLoading}
              variant="secondary-icon-btn"
              onClick={handleExportAllCustomer}
            >
              <div className="flex items-center gap-2">
                <i className="shrink-0">
                  <Icon.McsIcExportPdf />
                </i>
                <span>Export CSV</span>
              </div>
            </CustomButton>

            <CustomButton
              variant="primary-icon-btn"
              onClick={handleOpenAddModal}
            >
              <div className="flex items-center gap-2">
                <i className="shrink-0">
                  <Icon.McsIcIconPlus />
                </i>
                <span>Add Customer</span>
              </div>
            </CustomButton>
            {/* Add Customer modal */}
            {isAddModalOpen && (
              <AddCustomerContainer
                handleCloseAddModal={handleCloseAddModal}
                reFetchData={setReFetchData}
                updateId={updateId}
              />
            )}
            {/* Add Customer modal end */}
          </div>
        }
      />
      {/* Delete modal */}
      <ConfirmDeleteComponent
        title="Are you sure you want to delete this Customer?"
        message="Deleted data cannot be retrieved"
        isOpen={isDeleteModalOpen}
        toggle={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteModal}
        className="custom-delete-modal"
      />
    </>
  );
};

export default Datatable;
