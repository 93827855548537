import React from "react";
import { sideBarData } from "./sidebar.data";
import SiderBarHeader from "../../../molecules/sidebar-header";
import { Icon } from "src/components/atoms/icons";
import SidebarItem from "../../sidebar-item";

import { ROLES } from "src/pages/admin-user-management/elements/datatable/datatable.types";
import useUser from "src/hooks/useUser";

const activeClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] bg-primary-100";
const inactiveClass =
  "flex items-center justify-start gap-3 px-16 py-12 transition duration-[0.4s] hover:bg-gray-300";

const SideBarMainComponent = ({ isSidebarOpen, toggleSidebar }: any) => {
  const isLoading = false;
  const user = useUser();

  const getUserRoutes = () => {
    let routes: any[] = [];
    const index = sideBarData.findIndex(
      (item: any) => item.route === "/admin/admin-user-management",
    );
    if (index > -1) {
      routes = sideBarData.filter(
        (item: any) => item.route !== "/admin/admin-user-management",
      );
    }
    return routes;
  };

  const getCurrentRoutes = () => {
    if (user?.role === ROLES.USER) {
      return userRoutes;
    } else {
      return adminRoutes;
    }
  };

  const adminRoutes = sideBarData;
  const userRoutes = getUserRoutes();

  return (
    <div className="flex h-full flex-col bg-primary-50 ">
      <div
        className={`flex justify-between border-b ${isLoading ? "" : "border-primary-300"}`}
      >
        <SiderBarHeader />
        {isSidebarOpen && (
          <button
            className="flex px-16 py-10 md:hidden"
            onClick={toggleSidebar}
          >
            <Icon.McsIcMenu />
          </button>
        )}
      </div>
      <ul className="theme-custom-scroll flex h-[90%] flex-col overflow-y-auto transition-all duration-300 ease-in-out">
        {getCurrentRoutes().map((val, i) => (
          <SidebarItem
            key={val?.route}
            val={val}
            activeClass={activeClass}
            inactiveClass={inactiveClass}
            isLoading={isLoading}
          />
        ))}
      </ul>
      <div className="relative mt-auto flex items-end  px-16 pb-14 pt-40">
        <p className="shrink-0 text-14 font-normal leading-20 text-brand-color">
          Powered by
        </p>
        <img
          className="absolute bottom-[0] right-[0]"
          src="/assets/images/mcs-marinair-logo.svg"
          alt="Sidebar Brand Logo"
        />
      </div>
    </div>
  );
};

export default SideBarMainComponent;
