import React from "react";
import FormHeader from "../../elements/form-header/form-header.component";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { ISignUpForm } from "./sign-up-form.types";
import ErrorLabel from "src/components/atoms/error-label";
import { Icon } from "src/components/atoms/icons";
import { useNavigate } from "react-router-dom";

const SignUpForm = ({ formik, error, resetError, loading }: ISignUpForm) => {
  const navigate = useNavigate();
  return (
    <div>
      <FormHeader
        title="Join myMCS"
        description="Get your quotations in only few seconds"
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-32">
          <TextInput
            name="email"
            type="email"
            label="Email Address"
            placeholder="Enter your email address"
            classes={{ containerClassName: "mb-12", inputClassName: "" }}
            value={formik.values.email}
            showError={Boolean(formik.touched.email)}
            onChange={(e: any) => {
              resetError();
              formik.handleChange(e);
              const email = e.target.value.replace(/\s/g, "");
              email && formik.setFieldValue("email", email);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.email}
            prefix={<Icon.McsIcEmail />}
            autoFocus
            required
          />
          <TextInput
            name="name"
            type="text"
            label="Customer Name"
            placeholder="Enter your name"
            classes={{ containerClassName: "mb-12", inputClassName: "" }}
            value={formik.values.name}
            showError={Boolean(formik.touched.name)}
            onChange={(e: any) => {
              resetError();
              formik.handleChange(e);

              formik.setFieldValue("name", e.target.value);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.name}
            prefix={<Icon.McsIcBuilding />}
            required
          />
          <TextInput
            name="password"
            type="password"
            label="Password"
            placeholder="Enter your password"
            classes={{ containerClassName: "mb-12" }}
            value={formik.values.password}
            showError={Boolean(formik.touched.password)}
            onChange={(e) => {
              resetError();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.password}
            required={true}
            prefix={<Icon.McsIcPasswrod />}
          />
          <TextInput
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            placeholder="Enter your password again"
            classes={{ containerClassName: "mb-12" }}
            value={formik.values.confirmPassword}
            showError={Boolean(formik.touched.confirmPassword)}
            onChange={(e) => {
              resetError();
              formik.handleChange(e);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError();
            }}
            errorMessage={formik.errors.confirmPassword}
            required={true}
            prefix={<Icon.McsIcPasswrod />}
          />
          {Boolean(error) && <ErrorLabel message={error} />}
        </div>
        <div className="flex flex-col gap-3">
          <CustomButton htmlType="submit" variant="primary" loading={loading}>
            Sign up
          </CustomButton>
          <CustomButton
            htmlType="button"
            variant="secondary"
            onClick={() => navigate("/login")}
          >
            Already have an account? Sign in.
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
