import React, { useEffect, useState } from "react";
import ContentWrapper from "src/components/layouts/content-wrapper";
import { useGetQuotationDetailsMutation } from "src/services/api-service/quotations";
import { useSearchParams } from "react-router-dom";
import QuotationsResultFilters from "src/pages/quotation-results/elements/quotation-results-filters";
import CustomerPanelLayout from "src/components/layouts/customer-panel-layout";
import BackButton from "src/components/atoms/back-button";
import QuotationResultsCard from "src/pages/quotation-results-customer/elements/quotation-results-card";

const CustomerQuotationsResultComponent = () => {
  const [url] = useSearchParams();
  const id: string = url?.get("id") || "";

  const [getDetailsMutation] = useGetQuotationDetailsMutation<any>();
  const [reload] = useState(false);

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    id && getDetailsMutation({ id });
  };

  useEffect(() => {
    if (reload) getDetails();
  }, [reload]);

  const href = `/quotations?tab=Generate%20Quotations&id=${id}`;

  return (
    <CustomerPanelLayout
      isSidebarOpen={true}
      isResultsPage={true}
      breadcrumbsData={[
        { title: "Generate Quotations", href: href },
        { title: "Results" },
      ]}
    >
      <ContentWrapper>
        <BackButton text="Back to edit search" href={href} />
        <QuotationsResultFilters getDetails={getDetails} isCustomer={true} />
        <QuotationResultsCard />
      </ContentWrapper>
    </CustomerPanelLayout>
  );
};

export default CustomerQuotationsResultComponent;
