import { IResponse } from "../../types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

// Define a type for the country
interface Country {
  name: string;
  code: string;
}

const APIS = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getCountriesList: build.mutation<Country[], Partial<any>>({
      query: (body) => {
        const filteredParams = Object.fromEntries(
          Object.entries(body).filter(([key, value]) => value !== ""),
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        return {
          url: ENDPOINTS.ADMIN.COUNTRIES_LIST + `?${queryString}`,
          method: "GET",
        };
      },
      transformResponse: (res: IResponse) => {
        // Assuming the response contains an array of countries directly
        // You may need to adjust this depending on the actual response structure
        return res.data as Country[];
      },
    }),
  }),
});

export const { useGetCountriesListMutation } = APIS;
