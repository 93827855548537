import React, { useEffect, useState } from "react";
import { useQuotationsContext } from "src/context/quotations-context";
import TextInput from "src/components/atoms/text-input";
import { extractNumbers } from "src/utils";
import { useSearchParams } from "react-router-dom";
import { quotationsSelector } from "src/store/features/quotations/selectors";
import { useAppSelector } from "src/store/hook";
import { removeCommas } from "src/helpers";

export default function PricingDetails() {
  const [search] = useSearchParams();

  const { data: quotationData } = useAppSelector(quotationsSelector);

  const id = search.get("id") ?? "";

  const { formikQuotation } = useQuotationsContext();

  const [arr, setArr] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);
  const [arr1, setArr1] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);
  const [arr2, setArr2] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);
  const [arr3, setArr3] = useState([
    { carriage: "", type: "", amount: "", fill: false },
  ]);

  const [charges, setCharges] = useState<any>({
    preCarriage: [],
    origin: [],
    oceanFreight: [],
    destination: [],
  });

  const handleChange = (
    value: any,
    key: string,
    i: number,
    arrName?: string,
    increment?: boolean,
  ) => {
    let newArr: any;
    switch (arrName) {
      case "arr1": {
        newArr = [...arr1];
        newArr[i][key] = value;
        !increment && arr1.slice(i, 1);
        increment && setArr1(newArr);
        setArr1(newArr);
        break;
      }
      case "arr2": {
        newArr = [...arr2];
        newArr[i][key] = value;
        !increment && arr2.slice(i, 1);
        increment && setArr2(newArr);
        setArr2(newArr);
        break;
      }
      case "arr3": {
        newArr = [...arr3];
        newArr[i][key] = value;
        !increment && arr3.slice(i, 1);
        increment && setArr3(newArr);
        setArr3(newArr);
        break;
      }
      default: {
        newArr = [...arr];
        newArr[i][key] = value;
        !increment && arr.slice(i, 1);
        increment && setArr(newArr);
        setArr(newArr);
        break;
      }
    }
    formikQuotation.setFieldValue("charges", charges);
  };

  const crudCharges = (
    index: number,
    type: string,
    arrName: string,
    increment: boolean,
  ) => {
    if (!increment && charges[type]?.length === index + 1) {
      charges[type].splice(index, 1);
    }
    if (
      increment &&
      arrName === "arr" &&
      arr[index].carriage &&
      arr[index].amount
    ) {
      charges[type][index] = {
        [arr[index].carriage]: arr[index].amount,
      };
    }
    if (
      increment &&
      arrName === "arr1" &&
      arr1[index].carriage &&
      arr1[index].amount
    ) {
      charges[type][index] = {
        [arr1[index].carriage]: arr1[index].amount,
      };
    }
    if (
      increment &&
      arrName === "arr2" &&
      arr2[index].carriage &&
      arr2[index].amount
    ) {
      charges[type][index] = {
        [arr2[index].carriage]: arr2[index].amount,
      };
    }
    if (
      increment &&
      arrName === "arr3" &&
      arr3[index].carriage &&
      arr3[index].amount
    ) {
      charges[type][index] = {
        [arr3[index].carriage]: arr3[index].amount,
      };
    }
  };

  const handleInput = (index: number, arrName: string) => {
    let newArray: any[] = [];
    if (arrName === "arr") {
      newArray = [...arr];
      newArray.splice(index, 1);
      setArr(newArray);
    }
    if (arrName === "arr1") {
      newArray = [...arr1];
      newArray.splice(index, 1);
      setArr1(newArray);
    }
    if (arrName === "arr2") {
      newArray = [...arr2];
      newArray.splice(index, 1);
      setArr2(newArray);
    }
    if (arrName === "arr3") {
      newArray = [...arr3];
      newArray.splice(index, 1);
      setArr3(newArray);
    }
  };

  useEffect(() => {
    formikQuotation.setFieldValue("charges", charges);
  }, [charges]);

  const checkFill = (arrName: string) => {
    return false;
    // Code used in previous version
    // if (arrName === "arr") {
    //   if (arr.filter((item: any) => !item.fill)) return true;
    //   else return false;
    // }
    // if (arrName === "arr1") {
    //   if (arr1.filter((item: any) => !item.fill)) return true;
    //   else return false;
    // }
    // if (arrName === "arr2") {
    //   if (arr2.filter((item: any) => !item.fill)) return true;
    //   else return false;
    // }
  };

  useEffect(() => {
    const obj = {
      origin: arr1.filter((e: any) => e.fill),
      oceanFreight: arr2.filter((e: any) => e.fill),
      preCarriage: arr.filter((e: any) => e.fill),
      destination: arr3.filter((e: any) => e.fill),
    };

    formikQuotation.setFieldValue("charges", obj);
  }, [arr, arr1, arr2, arr3]);

  useEffect(() => {
    if (id && quotationData?.quotations[0]?.quotation_type == "custom") {
      const mapCharges = (chargesArray: any, targetArray: any) => {
        const newArr =
          chargesArray?.map((item: any) => ({
            carriage: item?.type,
            type: "",
            amount: item?.charges,
            fill: true,
          })) || targetArray;
        return newArr.length == 0 ? targetArray : newArr;
      };

      setArr(mapCharges(formikQuotation?.values?.charges?.preCarriage, arr));
      setArr1(mapCharges(formikQuotation?.values?.charges?.origin, arr1));
      setArr2(mapCharges(formikQuotation?.values?.charges?.oceanFreight, arr2));
      setArr3(mapCharges(formikQuotation?.values?.charges?.destination, arr3));

      setCharges({
        preCarriage: mapCharges(
          formikQuotation?.values?.charges?.preCarriage,
          arr,
        ),
        origin: mapCharges(formikQuotation?.values?.charges?.origin, arr1),
        oceanFreight: mapCharges(
          formikQuotation?.values?.charges?.oceanFreight,
          arr2,
        ),
        destination: mapCharges(
          formikQuotation?.values?.charges?.destination,
          arr3,
        ),
      });
    }
  }, [formikQuotation?.values?.quotations, quotationData]);

  return (
    <div className="mb-24 px-24 pb-16">
      <h1 className="mb-16 text-24 font-semibold">Pricing Details</h1>
      <div className=" grid  gap-2 ">
        {/* Pre-Carriage */}
        <div className="rounded-md border border-solid p-10 pt-5">
          <p className="my-7 flex gap-[10px] text-base font-thin">
            <b>Pre-Carriage</b>
            <button
              disabled={checkFill("arr")}
              onClick={() => {
                setArr([
                  ...arr,
                  { carriage: "", type: "", amount: "", fill: false },
                ]);
              }}
            >
              <img
                className=""
                src={"/assets/icons/plus-icon.svg"}
                alt="maersk"
              ></img>
            </button>
          </p>
          {arr.map((item, index) => {
            return (
              <div key={index} className=" mb-5 flex gap-5 ">
                <div className="w-full">
                  <TextInput
                    name="carriage"
                    type="text"
                    disabled={item.fill}
                    value={removeCommas(item.carriage)}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "carriage", index)
                    }
                    required={true}
                    placeholder="Type here..."
                    // label="Pre-Carriage"
                  />
                </div>

                <div className="w-1/2">
                  <TextInput
                    name="amount"
                    type="number"
                    disabled={item.fill}
                    value={removeCommas(item.amount)}
                    onChange={(e: any) =>
                      handleChange(
                        extractNumbers(e.target.value),
                        "amount",
                        index,
                        "arr",
                      )
                    }
                    required={true}
                    placeholder="Amount"
                    // label="Amount"
                  />
                </div>
                <div className="flex w-full items-center space-x-6">
                  {!!item.carriage && !!item.amount && !item.fill && (
                    <>
                      <button
                        onClick={() => {
                          handleChange(true, "fill", index, "arr");
                          crudCharges(index, "preCarriage", "arr", true);
                        }}
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/Tick.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr", false);
                          handleChange("", "amount", index, "arr", false);
                          crudCharges(index, "preCarriage", "arr", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Cross-red.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {item.fill && (
                    <>
                      <button
                        onClick={() =>
                          handleChange(false, "fill", index, "arr")
                        }
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/PencilSimpleLine.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr", false);
                          handleChange("", "amount", index, "arr", false);
                          handleChange(false, "fill", index, "arr");
                          crudCharges(index, "preCarriage", "arr", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Trash-icon.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {index > 0 && (
                    <button
                      onClick={() => {
                        handleInput(index, "arr");
                        crudCharges(index, "preCarriage", "arr", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/remove-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Origin
         */}
        <div className="rounded-md border border-solid p-10 pt-5">
          <p className="my-7 flex gap-[10px] text-base font-thin">
            <b>Origin</b>
            <button
              disabled={checkFill("arr1")}
              onClick={() => {
                setArr1([
                  ...arr1,
                  { carriage: "", type: "", amount: "", fill: false },
                ]);
              }}
            >
              <img
                className=""
                src={"/assets/icons/plus-icon.svg"}
                alt="maersk"
              ></img>
            </button>
          </p>
          {arr1.map((item, index) => {
            return (
              <div key={index} className=" mb-5 flex gap-5 ">
                <div className="w-full">
                  <TextInput
                    name="carriage"
                    type="text"
                    disabled={item.fill}
                    value={item.carriage}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "carriage", index, "arr1")
                    }
                    required={true}
                    placeholder="Type here..."
                    // label="Origin Charges"
                  />
                </div>
                <div className="w-1/2">
                  <TextInput
                    name="amount"
                    type="number"
                    disabled={item.fill}
                    value={removeCommas(item.amount)}
                    onChange={(e: any) =>
                      handleChange(
                        extractNumbers(e.target.value),
                        "amount",
                        index,
                        "arr1",
                      )
                    }
                    required={true}
                    placeholder="Amount"
                    // label="Amount"
                  />
                </div>
                <div className="flex w-full items-center space-x-6">
                  {!!item.carriage && !!item.amount && !item.fill && (
                    <>
                      <button
                        onClick={() => {
                          handleChange(true, "fill", index, "arr1");
                          crudCharges(index, "origin", "arr1", true);
                        }}
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/Tick.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr1", false);
                          handleChange("", "amount", index, "arr1", false);
                          crudCharges(index, "origin", "arr1", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Cross-red.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {item.fill && (
                    <>
                      <button
                        onClick={() =>
                          handleChange(false, "fill", index, "arr1")
                        }
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/PencilSimpleLine.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr1", false);
                          handleChange("", "amount", index, "arr1", false);
                          handleChange(false, "fill", index, "arr1");
                          crudCharges(index, "origin", "arr1", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Trash-icon.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {index > 0 && (
                    <button
                      onClick={() => {
                        handleInput(index, "arr1");
                        crudCharges(index, "origin", "arr1", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/remove-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Ocean Freight */}
        <div className="rounded-md border border-solid p-10 pt-5">
          <p className="my-7 flex gap-[10px] text-base font-thin">
            <b>Ocean Freight</b>
            <button
              disabled={checkFill("arr2")}
              onClick={() => {
                setArr2([
                  ...arr2,
                  { carriage: "", type: "", amount: "", fill: false },
                ]);
              }}
            >
              <img
                className=""
                src={"/assets/icons/plus-icon.svg"}
                alt="maersk"
              ></img>
            </button>
          </p>
          {arr2.map((item, index) => {
            return (
              <div key={index} className=" mb-5 flex gap-5 ">
                <div className="w-full">
                  <TextInput
                    name="carriage"
                    type="text"
                    disabled={item.fill}
                    value={item.carriage}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "carriage", index, "arr2")
                    }
                    required={true}
                    placeholder="Type here..."
                    // label="Ocean Freight"
                  />
                </div>
                <div className="w-1/2">
                  <TextInput
                    name="amount"
                    type="number"
                    disabled={item.fill}
                    value={item.amount}
                    onChange={(e: any) =>
                      handleChange(
                        extractNumbers(e.target.value),
                        "amount",
                        index,
                        "arr2",
                      )
                    }
                    required={true}
                    placeholder="Amount"
                    // label="Amount"
                  />
                </div>
                <div className="flex w-full items-center space-x-6">
                  {!!item.carriage && !!item.amount && !item.fill && (
                    <>
                      <button
                        onClick={() => {
                          handleChange(true, "fill", index, "arr2");
                          crudCharges(index, "oceanFreight", "arr2", true);
                        }}
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/Tick.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr2", false);
                          handleChange("", "amount", index, "arr2", false);
                          crudCharges(index, "oceanFreight", "arr2", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Cross-red.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {item.fill && (
                    <>
                      <button
                        onClick={() => {
                          handleChange(false, "fill", index, "arr2");
                          crudCharges(index, "oceanFreight", "arr2", true);
                        }}
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/PencilSimpleLine.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr2", false);
                          handleChange("", "amount", index, "arr2", false);
                          handleChange(false, "fill", index, "arr2");
                          crudCharges(index, "oceanFreight", "arr2", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Trash-icon.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {index > 0 && (
                    <button
                      onClick={() => {
                        handleInput(index, "arr2");
                        crudCharges(index, "oceanFreight", "arr2", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/remove-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Destination */}
        <div className="rounded-md border border-solid p-10 pt-5">
          <p className="my-7 flex gap-[10px] text-base font-thin">
            <b>Destination</b>
            <button
              disabled={checkFill("arr3")}
              onClick={() => {
                setArr3([
                  ...arr3,
                  { carriage: "", type: "", amount: "", fill: false },
                ]);
              }}
            >
              <img
                className=""
                src={"/assets/icons/plus-icon.svg"}
                alt="maersk"
              ></img>
            </button>
          </p>
          {arr3.map((item, index) => {
            return (
              <div key={index} className=" mb-5 flex gap-5 ">
                <div className="w-full">
                  <TextInput
                    name="carriage"
                    type="text"
                    disabled={item.fill}
                    value={item.carriage}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "carriage", index, "arr3")
                    }
                    required={true}
                    placeholder="Type here..."
                  />
                </div>
                <div className="w-1/2">
                  <TextInput
                    name="amount"
                    type="number"
                    disabled={item.fill}
                    value={removeCommas(item.amount)}
                    onChange={(e: any) =>
                      handleChange(
                        extractNumbers(e.target.value),
                        "amount",
                        index,
                        "arr3",
                      )
                    }
                    required={true}
                    placeholder="Amount"
                    // label="Amount"
                  />
                </div>
                <div className="flex w-full items-center space-x-6">
                  {!!item.carriage && !!item.amount && !item.fill && (
                    <>
                      <button
                        onClick={() => {
                          handleChange(true, "fill", index, "arr3");
                          crudCharges(index, "destination", "arr3", true);
                        }}
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/Tick.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr3", false);
                          handleChange("", "amount", index, "arr3", false);
                          crudCharges(index, "destination", "arr3", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Cross-red.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {item.fill && (
                    <>
                      <button
                        onClick={() =>
                          handleChange(false, "fill", index, "arr3")
                        }
                      >
                        <img
                          className="flex items-center"
                          src={"/assets/icons/PencilSimpleLine.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                      <button
                        onClick={() => {
                          handleChange("", "carriage", index, "arr3", false);
                          handleChange("", "amount", index, "arr3", false);
                          handleChange(false, "fill", index, "arr3");
                          crudCharges(index, "destination", "arr3", false);
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/icons/Trash-icon.svg"}
                          alt="maersk"
                        ></img>
                      </button>
                    </>
                  )}

                  {index > 0 && (
                    <button
                      onClick={() => {
                        handleInput(index, "arr3");
                        crudCharges(index, "destination", "arr3", false);
                      }}
                    >
                      <img
                        className=""
                        src={"/assets/icons/remove-icon.svg"}
                        alt="maersk"
                      ></img>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="grid grid-cols-2 lg:grid-cols-6">
        <div className="mb-6font-medium text-gray-600">
          <CustomSelect
            name="test"
            options={[{ label: "300", value: "300" }]}
            required={true}
            placeholder="$ 300"
            label="Total Billing Price"
          />
        </div>
      </div> */}
    </div>
  );
}
