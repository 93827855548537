import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useGetBookingDetailsMutation } from "src/services/api-service/quotations";

export default function useGetBookingDetails(id: string | undefined) {
  const [getBookingDetails] = useGetBookingDetailsMutation();
  const [data, setData] = useState<any>(null);
  const [isError, setIsError] = useState<
    FetchBaseQueryError | SerializedError | null
  >(null);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (!id) return;

    let ignore = false;

    const getData = async () => {
      if (ignore) return;

      setIsPending(true);

      const res = await getBookingDetails({ id });

      if ("data" in res) {
        setData(res.data.data);
        setIsPending(false);
      } else if ("error" in res) {
        setIsError(res.error);
        setIsPending(false);
      }
    };

    getData();

    return () => {
      ignore = true;
    };
  }, [id]);

  return { data, isPending, isError };
}
