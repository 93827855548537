import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import Datatable from "./datatable";
import ContentWrapper from "src/components/layouts/content-wrapper";
import ContentHeader from "src/components/layouts/content-header";
import CustomLink from "src/components/atoms/custom-link";
import { IBreadcrumbItem } from "src/types";

const Users = () => {
  const breadcrumbsData: IBreadcrumbItem[] = [
    {
      title: "Customer Management",
      href: `/admin/customer-management`,
    },
    {
      title: "Users",
    },
  ];

  return (
    <PanelLayout breadcrumbsData={breadcrumbsData}>
      <>
        <ContentHeader>
          <div className="">
            <CustomLink href={""}>Back to Customers</CustomLink>
          </div>
        </ContentHeader>
        <ContentWrapper>
          <Datatable />
        </ContentWrapper>
      </>
    </PanelLayout>
  );
};

export default Users;
